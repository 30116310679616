import React, { useEffect, useRef, useState, useCallback } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import Hammer from 'hammerjs';
import { Box, Typography, IconButton } from '@mui/material';
import { ZoomIn, ZoomOut, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneWADOImageLoader.configure({
  beforeSend: function (xhr) {
    // Add custom headers if needed
  },
  useWebWorkers: true,
});

const DicomViewer = ({ files, selectedFile }) => {
  const viewerRef = useRef(null);
  const containerRef = useRef(null);
  const [imageIds, setImageIds] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [dcmCount, setDcmCount] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isCornerStoneEnabled, setIsCornerStoneEnabled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const viewerElement = viewerRef.current;

    const enableCornerstone = () => {
      if (viewerElement && !isCornerStoneEnabled) {
        try {
          cornerstone.enable(viewerElement);
          setIsCornerStoneEnabled(true);
        } catch (error) {
          console.error('Error enabling cornerstone:', error);
        }
      }
    };

    enableCornerstone();

    return () => {
      if (isCornerStoneEnabled && viewerElement) {
        cornerstone.disable(viewerElement);
      }
    };
  }, [isCornerStoneEnabled]);

  useEffect(() => {
    if (files && files.length > 0) {
      const newImageIds = files.map(({ file }) => {
        const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
        return imageId;
      });

      const names = files.map(({ file }) => file.name);
      setImageIds(newImageIds);
      setFileNames(names);
      setCurrentImageIndex(0);
      setScrollPosition(0);
      setDcmCount(files.length);
    }
  }, [files]);

  const loadAndResizeImage = useCallback(async (imageId, element) => {
    if (!element) return;

    try {
      const image = await cornerstone.loadAndCacheImage(imageId);
      cornerstone.displayImage(element, image);
      cornerstone.resize(element, true);

      // Force a re-render after a short delay to ensure smooth transition
      setTimeout(() => {
        cornerstone.resize(element, true);
      }, 300);
    } catch (error) {
      console.error('Error loading image:', error);
    }
  }, []);

  useEffect(() => {
    if (selectedFile && isCornerStoneEnabled && viewerRef.current) {
      const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(selectedFile);
      loadAndResizeImage(imageId, viewerRef.current);

      // Update the current image index and file name
      const selectedIndex = files.findIndex(file => file.file === selectedFile);
      if (selectedIndex !== -1) {
        setCurrentImageIndex(selectedIndex);
        setScrollPosition((selectedIndex / (files.length - 1)) * 100);
      }
    }
  }, [selectedFile, isCornerStoneEnabled, loadAndResizeImage, files]);

  useEffect(() => {
    if (imageIds.length > 0 && viewerRef.current && isCornerStoneEnabled) {
      loadAndResizeImage(imageIds[currentImageIndex], viewerRef.current);
    }
  }, [imageIds, currentImageIndex, isCornerStoneEnabled, loadAndResizeImage, isFullScreen]);

  const updateCurrentImage = useCallback(
    (position) => {
      const newIndex = Math.min(Math.floor((position / 100) * (imageIds.length - 1)), imageIds.length - 1);
      setCurrentImageIndex(newIndex);
    },
    [imageIds.length]
  );

  const handleScrollbarDrag = useCallback(
    (clientY) => {
      const { top, height } = viewerRef.current.getBoundingClientRect();
      const newPosition = Math.max(0, Math.min(100, ((clientY - top) / height) * 100));
      setScrollPosition(newPosition);
      updateCurrentImage(newPosition);
    },
    [updateCurrentImage]
  );

  const handleMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      setIsDragging(true);
      handleScrollbarDrag(event.clientY);
    },
    [handleScrollbarDrag]
  );

  const handleMouseMove = useCallback(
    (event) => {
      if (isDragging) {
        event.preventDefault();
        handleScrollbarDrag(event.clientY);
      }
    },
    [isDragging, handleScrollbarDrag]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      containerRef.current
        .requestFullscreen()
        .catch((err) => console.error(`Error attempting to enable full-screen mode: ${err.message}`));
    } else {
      document.exitFullscreen();
    }
  }, []);

  const handleWheel = useCallback((event) => {
    event.preventDefault();
    const direction = event.deltaY > 0 ? 1 : -1;
    const newIndex = Math.max(0, Math.min(imageIds.length - 1, currentImageIndex + direction));
    setCurrentImageIndex(newIndex);
    setScrollPosition((newIndex / (imageIds.length - 1)) * 100);
  }, [currentImageIndex, imageIds.length]);

  const handleArrowClick = useCallback(
    (direction) => {
      const step = 1;
      const newIndex = direction === 'up'
        ? Math.max(0, currentImageIndex - step)
        : Math.min(imageIds.length - 1, currentImageIndex + step);
      setCurrentImageIndex(newIndex);
      setScrollPosition((newIndex / (imageIds.length - 1)) * 100);
    },
    [currentImageIndex, imageIds.length]
  );

  useEffect(() => {
    const viewer = viewerRef.current;
    if (viewer) {
      viewer.addEventListener('wheel', handleWheel, { passive: false });
    }
    return () => {
      if (viewer) {
        viewer.removeEventListener('wheel', handleWheel);
      }
    };
  }, [handleWheel]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  if (files.length === 0 && !selectedFile) {
    return null;
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        width: 200,
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'black',
        borderRadius: '4px',
        border: '1px solid #ccc',
        position: 'relative',
        overflow: 'hidden',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
      }}
    >
      <div
        ref={viewerRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      ></div>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '20px',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '5px 0',
        }}
      >
        <IconButton
          size="small"
          onClick={() => handleArrowClick('up')}
          sx={{ color: 'rgba(255, 255, 255, 0.7)', p: 0 }}
        >
          <KeyboardArrowUp fontSize="small" />
        </IconButton>

        <Box
          sx={{
            flex: 1,
            width: '100%',
            position: 'relative',
            cursor: 'pointer',
            my: 1,
          }}
          onMouseDown={handleMouseDown}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '2px',
              right: '2px',
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '4px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: `${scrollPosition}%`,
              left: '2px',
              right: '2px',
              height: '20px',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '4px',
              transform: 'translateY(-50%)',
            }}
          />
        </Box>

        <IconButton
          size="small"
          onClick={() => handleArrowClick('down')}
          sx={{ color: 'rgba(255, 255, 255, 0.7)', p: 0 }}
        >
          <KeyboardArrowDown fontSize="small" />
        </IconButton>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: isFullScreen ? 16 : 4,
          left: isFullScreen ? 16 : 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            padding: isFullScreen ? '4px 8px' : '1px 3px',
            borderRadius: '4px',
            marginBottom: '4px',
            fontSize: isFullScreen ? '1rem' : '0.6rem',
          }}
        >
          {fileNames[currentImageIndex]}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            padding: isFullScreen ? '4px 8px' : '1px 3px',
            borderRadius: '4px',
            fontSize: isFullScreen ? '1rem' : '0.6rem',
          }}
        >
          {currentImageIndex + 1} / {dcmCount}
        </Typography>
      </Box>

      <IconButton
        onClick={toggleFullScreen}
        sx={{
          position: 'absolute',
          top: isFullScreen ? 16 : 4,
          right: isFullScreen ? 46 : 24,
          color: 'white',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          },
        }}
      >
        {isFullScreen ? <ZoomOut /> : <ZoomIn />}
      </IconButton>
    </Box>
  );
};

export default DicomViewer;