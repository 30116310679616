import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  LinearProgress,
  Paper
} from '@mui/material';
import {
  LockOutlined,
  Visibility,
  VisibilityOff,
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { auth, getFirebaseErrorMessage } from '../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";

// Use the default theme
const theme = createTheme();

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const calculatePasswordStrength = (password) => {
    if (password.length === 0) return 0;
    if (password.length < 6) return 1;
    let strength = 2;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/[0-9]/)) strength++;
    if (password.match(/[^a-zA-Z0-9]/)) strength++;
    return Math.min(strength, 5);
  };

  const getPasswordStrengthLabel = (strength) => {
    if (strength === 0) return '';
    if (strength === 1) return 'Too weak';
    if (strength === 2) return 'Could be stronger';
    if (strength === 3) return 'Decent';
    if (strength === 4) return 'Strong';
    return 'Very strong';
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');

    let valid = true;
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    }
    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 6 characters long.');
      valid = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      valid = false;
    }

    if (!valid) return;

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      localStorage.setItem('signupEmail', email);
      localStorage.setItem('signupPassword', password);
      const encodedEmail = encodeURIComponent(email);
      const stripeCheckoutUrl = `https://buy.stripe.com/4gw9BJfnAc4TagU288?prefilled_email=${encodedEmail}`;
      window.location.href = stripeCheckoutUrl;
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error.code);
      setError(errorMessage);
      console.error('Error during sign up:', error);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const passwordStrength = calculatePasswordStrength(password);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={4} alignItems="center">
            {/* Pricing and Features Information */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Paper
                elevation={3}
                sx={{
                  py: 3,
                  px: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: '#f5f5f5',
                  color: '#333',
                  maxWidth: '400px', // Adjust this value as needed
                }}
              >
                <Typography component="h1" variant="h5" sx={{ mb: 1 }} fontWeight="bold" align="left">
                  Pro Subscription
                </Typography>
                <Typography variant="h4" component="div" sx={{ mb: 1 }} fontWeight="bold" align="left">
                  $15<Typography variant="subtitle1" component="span">/month</Typography>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }} align="left">
                  Sign Up for a Pro Subscription and instantly unlock powerful features for your DICOM processing needs.
                </Typography>
                <Box sx={{ mt: 1 }}>
                  {['Unlimited DICOM uploads', 'Advanced anonymization', 'DICOM Header Editor', 'All conversion formats (PNG, TIFF, GIF, etc)'].map((feature) => (
                    <Box key={feature} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <CheckCircleOutlineIcon sx={{ mr: 1, color: 'success.main', fontSize: '1rem' }} />
                      <Typography variant="body2">{feature}</Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>

            {/* Sign Up Form */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign Up for Pro
                </Typography>

                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}

                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="new-password"
                        error={!!passwordError}
                        helperText={passwordError}
                        value={password}
                        onChange={handlePasswordChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {password.length > 0 && (
                        <>
                          <LinearProgress
                            variant="determinate"
                            value={(passwordStrength / 5) * 100}
                            sx={{ mt: 1, height: 5, borderRadius: 5 }}
                          />
                          <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>
                            Password strength: {getPasswordStrengthLabel(passwordStrength)}
                            {passwordStrength < 2 && " - Must be at least 6 characters"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!confirmPasswordError}
                        helperText={confirmPasswordError}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirm password visibility"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.5,
                        borderRadius: 2,
                      }}
                    >
                      Sign Up for Pro
                    </Button>
                  </motion.div>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href="/signin" variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;