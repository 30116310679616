import React from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const ContactUs = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
            textAlign: 'center',
          }}
        >
          <Typography variant={isMobile ? "h4" : "h3"} component="h1" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph sx={{ maxWidth: '80%', mb: 4 }}>
            Have questions or need assistance? Interested in learning more about an Enterpise Account? Our support team is here to help.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<EmailOutlinedIcon />}
            href="mailto:support@dicompro.com"
            sx={{
              borderRadius: '28px',
              padding: '10px 24px',
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            support@dicompro.com
          </Button>

          <Typography variant="body2" sx={{ mt: 4, color: 'text.secondary' }}>
            We typically respond within 24-48 business hours.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ContactUs;