// src/features/conversion/ImageConversionTool.js
import React from 'react';
import { Typography, Button, Box, FormControl, Select, MenuItem } from '@mui/material';
import { ToolContainer } from '../ui/StyledComponents';
import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';

const ImageConversionTool = ({ files, isProcessing, conversionFormat, setConversionFormat, onConvert, onDownload, convertedImagesReady }) => (
  <ToolContainer>
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      {/* Icon and Title */}
      <Box sx={{ textAlign: 'center' }}>
        <BurstModeOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />
        <Typography variant="h6" gutterBottom align="center">
          Image Conversion Tool
        </Typography>
      </Box>

      <Typography variant="body2" sx={{ mb: 2, flex: 1 }}>
        Convert DICOM files to standard image formats for easy viewing and sharing. Choose from various output formats to suit your needs.
      </Typography>

      {/* Convert To and Select Format */}
      <Box sx={{ mt: 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onConvert}
            disabled={isProcessing || files.length === 0}
            sx={{ mr: 1, flexGrow: 1 }}
          >
            Convert To
          </Button>
          <FormControl variant="outlined" size="small" sx={{ width: '30%' }}>
            <Select
              value={conversionFormat}
              onChange={(e) => setConversionFormat(e.target.value)}
            >
              <MenuItem value="jpeg">JPEG</MenuItem>
              <MenuItem value="png">PNG</MenuItem>
              <MenuItem value="webp">WebP</MenuItem>
              <MenuItem value="tiff">TIFF</MenuItem>
              <MenuItem value="bmp">BMP</MenuItem>
              <MenuItem value="gif">GIF</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Download Button */}
        <Button
          variant="contained"
          color="success"
          onClick={() => onDownload('converted_images')}
          disabled={!convertedImagesReady}
          fullWidth
        >
          Download Converted Images
        </Button>
      </Box>
    </Box>
  </ToolContainer>
);

export default ImageConversionTool;
