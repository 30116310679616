import JSZip from 'jszip';
import dcmjs from 'dcmjs';

export const formatSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
};

export const processFiles = async (fileList, basePath = '') => {
  let dcmFiles = [];
  let count = 0;
  let size = 0;
  let transferSyntax = '';

  for (let file of fileList) {
    const filePath = basePath ? `${basePath}/${file.name}` : file.name;

    if (file.name.endsWith('.dcm')) {
      dcmFiles.push({ file, path: filePath });
      count++;
      size += file.size;

      // Extract transfer syntax from the first DICOM file
      if (!transferSyntax) {
        const arrayBuffer = await file.arrayBuffer();
        const dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer);
        transferSyntax = dicomData.meta['00020010'].Value[0];
      }
    } else if (file.name.endsWith('.zip')) {
      try {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file);

        for (let filename in zipContent.files) {
          if (filename.endsWith('.dcm')) {
            const fileData = await zipContent.file(filename).async('blob');
            const dcmFile = new File([fileData], filename.split('/').pop(), { type: 'application/dicom' });

            dcmFiles.push({
              file: dcmFile,
              path: filename
            });

            count++;
            size += dcmFile.size;

            // Extract transfer syntax from the first DICOM file in the zip
            if (!transferSyntax) {
              const arrayBuffer = await dcmFile.arrayBuffer();
              const dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer);
              transferSyntax = dicomData.meta['00020010'].Value[0];
            }
          }
        }
      } catch (err) {
        console.error('Error processing ZIP file:', err);
      }
    }
  }

  return { dcmFiles, count, size, transferSyntax };
};

export const createAndDownloadZip = (files, zipName, conversionFormat) => {
  const zip = new JSZip();

  files.forEach(file => {
    if (file.data instanceof Blob) {
      zip.file(file.name, file.data);
    } else {
      // Convert base64 to blob for image files
      const byteString = atob(file.data.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: `image/${conversionFormat}` });
      zip.file(file.name, blob);
    }
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    const url = URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${zipName}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  });
};