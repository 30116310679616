// src/features/ui/StyledComponents.js
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const ToolContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
}));

export const UploadBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));