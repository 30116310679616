import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Button } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const CancelPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the temporarily stored email and password
    localStorage.removeItem('signupEmail');
    localStorage.removeItem('signupPassword');
  }, []);

  const handleRetry = () => {
    // Redirect the user back to the sign-up page
    navigate('/signup');
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
      <CancelOutlinedIcon sx={{ fontSize: 80, color: 'red', mb: 2 }} />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Subscription Canceled
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        You did not complete the payment. Please try again if you'd like to sign up for Pro features.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRetry}
          sx={{ mt: 2 }}
        >
          Retry Sign Up
        </Button>
      </Box>
    </Container>
  );
};

export default CancelPage;
