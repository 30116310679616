import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Switch, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { ANONYMIZATION_TAGS, ANONYMIZATION_GROUPS } from '../../utils/constants';
import * as dcmjs from 'dcmjs';

const AdvancedAnonymizationModal = ({ open, handleClose, onApply, initialToggles }) => {
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [groupToggles, setGroupToggles] = useState(initialToggles);
  const [tempToggles, setTempToggles] = useState(initialToggles);

  useEffect(() => {
    setTempToggles(initialToggles);
  }, [open, initialToggles]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedGroup(isExpanded ? panel : null);
  };

  const handleToggleChange = (group) => (event) => {
    event.stopPropagation();  // Prevent accordion from toggling when switch is clicked
    setTempToggles(prev => ({ ...prev, [group]: event.target.checked }));
  };

  const handleApply = () => {
    setGroupToggles(tempToggles);
    onApply(tempToggles);
    handleClose();
  };

  const handleCancel = () => {
    setTempToggles(groupToggles);
    handleClose();
  };

  const renderTagRow = (tagInfo, index) => (
    <TableRow key={`${tagInfo.tag}-${index}`}>
      <TableCell>{formatTag(tagInfo.tag)}</TableCell>
      <TableCell>{tagInfo.description || getTagName(tagInfo.tag)}</TableCell>
      <TableCell>
        {tagInfo.newValue === 'REMOVED' ? 'Removed' :
         tagInfo.newValue === 'ANONYMOUS' ? 'Set to "ANONYMOUS"' :
         tagInfo.newValue === 'ANONYMIZED UID' ? 'Replaced with anonymized UID' :
         tagInfo.newValue}
      </TableCell>
    </TableRow>
  );

  const formatTag = (tag) => {
    const cleanTag = tag.replace(/[^0-9A-Fa-f]/g, '').padStart(8, '0');
    return `(${cleanTag.slice(0, 4)},${cleanTag.slice(4)})`;
  };

  const getTagName = (tag) => {
    const formattedTag = formatTag(tag);
    const dictionary = dcmjs.data.DicomMetaDictionary.dictionary;
    return dictionary[formattedTag] ? dictionary[formattedTag].name || 'Unknown' : 'Unknown';
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 800,
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
      }}>
        <Button onClick={handleCancel} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </Button>
        <Typography variant="h6" component="h2" gutterBottom>
          Advanced Anonymization Details
        </Typography>
        <Typography variant="body2" paragraph>
          This tool anonymizes DICOM images by removing or modifying personal health information (PHI) to protect patient privacy while retaining essential medical data. Our process follows HIPAA, GDPR, and DICOM standards.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Key features:
        </Typography>
        <List dense disablePadding sx={{
          pl: 2,
          mb: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            p: 0,
            pb: 0.5,
          },
          '& .MuiListItemText-root': {
            m: 0,
          },
          '& .MuiTypography-root': {
            fontSize: '0.875rem',
          }
        }}>
          <ListItem>
            <ListItemText primary="• Remove or anonymize patient identifiers (name, ID, birth date)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Replace unique identifiers with anonymous values" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Modify dates, times, and location information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Anonymize healthcare provider and institution details" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Option to remove all private tags" />
          </ListItem>
        </List>
        <Typography variant="body2" paragraph>
          Below, you can customize anonymization settings for different groups of DICOM tags. Toggle switches to enable/disable anonymization for each group, and expand groups to see specific tags and how they'll be anonymized.
        </Typography>
        <Typography variant="body2" paragraph>
          By default, all toggles are enabled for the most conservative anonymization.
        </Typography>
        <Typography variant="body2" paragraph sx={{ fontWeight: 'bold' }}>
          Note: Private tags may contain sensitive information. Use the "Remove Private Tags" option to ensure complete anonymization. You can use our DICOM Header Editor after anonymization to check and edit these tags if necessary.
        </Typography>

        {ANONYMIZATION_GROUPS.map((group, index) => {
          const groupTags = ANONYMIZATION_TAGS.filter(tag => tag.group === group);
          return (
            <Accordion
              key={group}
              expanded={expandedGroup === index}
              onChange={handleAccordionChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <Switch
                  checked={tempToggles[group] || false}
                  onChange={handleToggleChange(group)}
                  onClick={(event) => event.stopPropagation()}
                  sx={{ mr: 2 }}
                />
                <Typography>{`${group} (${groupTags.length} tags)`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {group === 'Private Tags' ? (
                  <Typography>
                    When enabled, all private tags (those with odd group numbers) will be removed from the DICOM file.
                  </Typography>
                ) : (
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tag</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Anonymization Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupTags.map((tagInfo, index) => renderTagRow(tagInfo, index))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCancel} sx={{ mr: 1 }}>Cancel</Button>
          <Button onClick={handleApply} variant="contained" color="primary">Apply</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AdvancedAnonymizationModal;