import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

const DicomFileBrowser = ({ files, onFileSelect }) => {
  const [expandedFolders, setExpandedFolders] = useState({});

  const createFileTree = (files) => {
    const root = {};

    files.forEach((file) => {
      const parts = file.path.split('/');
      let current = root;

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];

        if (i === parts.length - 1) {
          if (!current.files) current.files = [];
          current.files.push(file);
        } else {
          if (!current[part]) current[part] = {};
          current = current[part];
        }
      }
    });

    return root;
  };

  const toggleFolder = (path) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const renderFileTree = (tree, path = '') => {
    const treeEntries = Object.entries(tree);

    if (treeEntries.length === 0) {
      return (
        <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
          No folders or files.
        </Typography>
      );
    }

    return treeEntries.map(([key, value]) => {
      if (key === 'files') {
        return value.map((fileObj, index) => (
          <Typography
            key={index}
            sx={{ fontSize: '0.7rem', color: 'text.secondary', pl: 2 }}
            onClick={() => onFileSelect(fileObj)}  // Pass the entire fileObj
            style={{ cursor: 'pointer' }}
          >
            📄 {fileObj.path.split('/').pop()}
          </Typography>
        ));
      }

      const currentPath = path ? `${path}/${key}` : key;
      const isExpanded = expandedFolders[currentPath];

      return (
        <React.Fragment key={currentPath}>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: path ? 2 : 0 }}>
            <IconButton size="small" onClick={() => toggleFolder(currentPath)}>
              {isExpanded ? <ExpandMore fontSize="small" /> : <ChevronRight fontSize="small" />}
            </IconButton>
            <Typography
              sx={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
                color: 'text.primary',
                cursor: 'pointer',
              }}
              onClick={() => toggleFolder(currentPath)}
            >
              📁 {key}
            </Typography>
          </Box>
          {isExpanded && (
            <Box sx={{ pl: 4 }}>
              {renderFileTree(value, currentPath)}
            </Box>
          )}
        </React.Fragment>
      );
    });
  };

  const fileTree = createFileTree(files);

  return (
    <Box
      sx={{
        width: 200,
        height: 200,
        overflowY: 'auto',
        fontFamily: 'monospace',
        whiteSpace: 'nowrap',
        border: '1px solid #ccc',
        borderRadius: '4px',
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.3)',
          borderRadius: '4px',
        },
        backgroundColor: '#f5f5f5',
      }}
    >
      {renderFileTree(fileTree)}
    </Box>
  );
};

export default DicomFileBrowser;