// DecompressTool.js
import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { ToolContainer } from '../ui/StyledComponents';

const DecompressTool = ({ files, isProcessing, onDecompress, onDownload, decompressedFilesReady, disabled }) => {
  return (
    <ToolContainer>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* Icon and Title */}
        <Box sx={{ textAlign: 'center' }}>
          <UnarchiveIcon sx={{ fontSize: 40, color: 'primary.main' }} />
          <Typography variant="h6" gutterBottom align="center">
            Decompression Tool
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ mb: 2, flex: 1 }}>
          Decompress DICOM files to remove compression and standardize transfer syntax for compatibility.
        </Typography>

        <Box sx={{ mt: 'auto' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onDecompress}
            disabled={isProcessing || files.length === 0 || disabled}
            fullWidth
            sx={{ mb: 1 }}
          >
            Decompress
          </Button>

          {/* Download Button */}
          <Button
            variant="contained"
            color="success"
            onClick={() => onDownload('decompressed_dicom_files')}
            disabled={!decompressedFilesReady}
            fullWidth
          >
            Download Decompressed Files
          </Button>
        </Box>
      </Box>
    </ToolContainer>
  );
};

export default DecompressTool;
