import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';  // Import sendEmailVerification
import { auth } from '../firebase';  // Import your Firebase config
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography, Container, Button, CircularProgress } from '@mui/material';

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the email and password from localStorage
    const email = localStorage.getItem('signupEmail');
    const password = localStorage.getItem('signupPassword');

    if (email && password) {
      // Create the Firebase account using email and password
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log('User account created:', user);

          // Send email verification
          sendEmailVerification(user)
            .then(() => {
              console.log('Verification email sent.');
            })
            .catch((error) => {
              console.error('Error sending email verification:', error);
            });

          // Clear the localStorage
          localStorage.removeItem('signupEmail');
          localStorage.removeItem('signupPassword');

          // Once the account is created, stop loading
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error creating user account:', error);
          setLoading(false);  // Stop loading if there's an error
        });
    } else {
      console.error('No email or password found in localStorage');
      setLoading(false);
    }
  }, []);

  const handleGoToDashboard = () => {
    window.location.href = '/account';
  };

  const handleGoToProTools = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <CheckCircleOutlineIcon sx={{ fontSize: 80, color: 'green', mb: 2 }} />
          <Typography variant="h4" sx={{ mb: 2 }}>
            Thanks for subscribing!
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            A payment to <strong>DICOM PRO</strong> will appear on your statement.
          </Typography>
          <Box
            sx={{
              backgroundColor: '#f9f9f9',
              border: '1px solid #e0e0e0',
              padding: '16px',
              borderRadius: '8px',
              mb: 4,
            }}
          >
            <Typography variant="h6">Subscription Details</Typography>
            <Typography variant="body1">Plan: <strong>Pro</strong></Typography>
            <Typography variant="body1">Amount: <strong>$15.00</strong></Typography>
            <Typography variant="body1">Status: <strong>Active</strong></Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoToDashboard}
              sx={{ mt: 2 }}
            >
              Go to Dashboard
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGoToProTools}
              sx={{ mt: 2 }}
            >
              Go to Pro Tools
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default SuccessPage;
