// decompress.js
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dcmjs from 'dcmjs';

// Initialize cornerstoneWADOImageLoader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;

// Configure the codecs for decoding compressed images
cornerstoneWADOImageLoader.webWorkerManager.initialize({
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: true,
  taskConfiguration: {
    'decodeTask': {
      codecsPath: '/cornerstone/',  // Update the path to where the WASM files are stored
      usePDFJS: false // optional: only if needed
    },
  },
});

class DICOMDecompressor {
  async decompressDicom(file) {
    return new Promise((resolve, reject) => {
      // Create an imageId for the file
      const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);

      // Load and cache the image
      cornerstone.loadAndCacheImage(imageId).then((image) => {
        try {
          // Get the decompressed pixel data
          const pixelData = image.getPixelData();

          // Read the original DICOM file using dcmjs
          const reader = new FileReader();
          reader.onload = (event) => {
            const arrayBuffer = event.target.result;
            let dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer);

            // Replace the pixel data with decompressed data
            dicomData.dict['7FE00010'] = {
              vr: 'OW',
              Value: [pixelData.buffer],
            };

            // Update the Transfer Syntax UID to Explicit VR Little Endian
            dicomData.meta['00020010'] = {
              vr: 'UI',
              Value: ['1.2.840.10008.1.2.1'],
            };

            // Remove any Group Length attributes (optional)
            delete dicomData.dict['7FE00000'];

            // Write the DICOM file back to an ArrayBuffer
            const decompressedArrayBuffer = dicomData.write();

            const decompressedBlob = new Blob([decompressedArrayBuffer], { type: 'application/dicom' });
            resolve(decompressedBlob);
          };

          reader.onerror = () => {
            reject(new Error('Failed to read file'));
          };

          reader.readAsArrayBuffer(file);
        } catch (error) {
          console.error('Decompression failed:', error);
          reject(new Error('Failed to decompress DICOM file: ' + error.message));
        }
      }).catch((err) => {
        console.error('Error loading image:', err);
        reject(new Error('Failed to load image: ' + err.message));
      });
    });
  }
}

const decompressor = new DICOMDecompressor();

export async function decompressDicom(file) {
  return decompressor.decompressDicom(file);
}
