import React, { useState } from 'react';
import { Typography, Button, Box, FormControl } from '@mui/material';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import AdvancedAnonymizationModal from './AdvancedAnonymizationModal';
import { ToolContainer } from '../ui/StyledComponents';
import { ANONYMIZATION_GROUPS } from '../../utils/constants';

const AnonymizationTool = ({ files, isProcessing, onAnonymize, onDownload, anonymizedFilesReady }) => {
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(
    ANONYMIZATION_GROUPS.reduce((acc, group) => {
      acc[group] = true;
      return acc;
    }, {})
  );

  const handleAdvancedClick = () => {
    setIsAdvancedModalOpen(true);
  };

  const handleApplyAdvancedSettings = (groupToggles) => {
    setSelectedGroups(groupToggles);
  };

  const handleAnonymize = () => {
    const settings = {
      ...selectedGroups,
      removePrivateTags: selectedGroups['Private Tags']
    };
    onAnonymize(settings);
  };

  return (
    <ToolContainer>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* Icon and Title */}
        <Box sx={{ textAlign: 'center' }}>
          <HealthAndSafetyOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />
          <Typography variant="h6" gutterBottom align="center">
            Anonymization Tool
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ mb: 2, flex: 1 }}>
          Remove or modify sensitive information from DICOM files to protect patient privacy while preserving essential medical data.
        </Typography>

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAnonymize}
              disabled={isProcessing || files.length === 0}
              sx={{ mr: 1, flexGrow: 1 }}
            >
              Anonymize
            </Button>
            <FormControl variant="outlined" size="small" sx={{ width: '30%' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAdvancedClick}
                fullWidth
              >
                Advanced
              </Button>
            </FormControl>
          </Box>

          {/* Download Button */}
          <Button
            variant="contained"
            color="success"
            onClick={() => onDownload('anonymized_dicom_files')}
            disabled={!anonymizedFilesReady}
            fullWidth
          >
            Download Anonymized Files
          </Button>
        </Box>
      </Box>

      <AdvancedAnonymizationModal
        open={isAdvancedModalOpen}
        handleClose={() => setIsAdvancedModalOpen(false)}
        onApply={handleApplyAdvancedSettings}
        initialToggles={selectedGroups}
      />
    </ToolContainer>
  );
};

export default AnonymizationTool;