import React from 'react';
import { Grid, Typography, Box, Container, Card, CardContent, useTheme } from '@mui/material';
import {
  Fingerprint as FingerprintIcon,
  Build as BuildIcon,
  CloudQueue as CloudQueueIcon,
  Security as SecurityIcon,
  ThumbUp as ThumbUpIcon,
  Devices as DevicesIcon,
} from '@mui/icons-material';

const FeatureCard = ({ icon, title, description, iconColor }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative', pt: 3, height: '100%' }}>
      <Card
        elevation={2}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -20,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: iconColor,
            color: theme.palette.getContrastText(iconColor),
            borderRadius: '50%',
            p: 2,
            zIndex: 1,
          }}
        >
          {React.cloneElement(icon, { fontSize: 'large' })}
        </Box>
        <CardContent sx={{ pt: 5, flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Typography variant="h6" component="h3" gutterBottom fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const WhyChooseDICOMPro = () => {
  const theme = useTheme();

  const features = [
    {
      icon: <FingerprintIcon />,
      title: "User-Friendly DICOM Processing",
      description: "Easily process and edit DICOM images with no learning curve or expensive software required.",
    },
    {
      icon: <BuildIcon />,
      title: "Comprehensive DICOM Tools",
      description: "A complete suite of DICOM tools for medical image anonymization, conversion, and header editing.",
    },
    {
      icon: <CloudQueueIcon />,
      title: "Cloud-Powered DICOM Access",
      description: "Access and process DICOM files securely from any device, anywhere with an internet connection.",
    },
    {
      icon: <SecurityIcon />,
      title: "Secure DICOM Anonymization",
      description: "Ensure patient privacy with local DICOM file processing and advanced anonymization tools.",
    },
    {
      icon: <ThumbUpIcon />,
      title: "Free DICOM Image Processing",
      description: "Start with high-quality DICOM processing for free, with flexible Pro upgrades available.",
    },
    {
      icon: <DevicesIcon />,
      title: "Cross-Platform DICOM Integration",
      description: "Effortlessly work with DICOM files across all your devices and platforms, including Windows, macOS, and mobile.",
    },
  ];

  return (
    <Box sx={{
      backgroundColor: theme.palette.grey[100],
      py: { xs: 8, md: 8 },
      position: 'relative',
      overflow: 'hidden',
    }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          align="center"
          sx={{
            mb: 6,
            fontWeight: 'normal',
            color: theme.palette.text.primary,
          }}
        >
          Why Choose DICOM Pro?
        </Typography>
        <Grid container spacing={6} justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureCard
                {...feature}
                iconColor={theme.palette.primary.main}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Decorative Elements */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          backgroundImage: `radial-gradient(${theme.palette.primary.light}22 8%, transparent 8%)`,
          backgroundPosition: '0 0',
          backgroundSize: '30px 30px',
          opacity: 0.3,
          zIndex: 0,
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
};

export default WhyChooseDICOMPro;
