import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { ToolContainer } from '../ui/StyledComponents';
import DicomHeaderEditorModal from './DicomHeaderEditorModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { handleEditHeaders, handleDownload } from './DicomHeaderEditor';

// Main component for DICOM Header Editor Tool
const DicomHeaderEditorTool = ({ files, isProcessing, setIsProcessing, setError, editedFiles, setEditedFiles }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [originalFiles, setOriginalFiles] = useState([]);

  // Update originalFiles when files prop changes
  useEffect(() => {
    setOriginalFiles(files);
  }, [files]);

  // Function to handle editing of DICOM headers
  const onEditHeaders = useCallback((editedHeaders) => {
    handleEditHeaders(originalFiles, editedHeaders, setIsProcessing, setError, setEditedFiles);
  }, [originalFiles, setIsProcessing, setError, setEditedFiles]);

  // Function to handle download of edited files
  const onDownload = useCallback(() => {
    handleDownload(editedFiles, setError);
  }, [editedFiles, setError]);

  // Function to open the modal and reset edited files
  const handleOpenModal = useCallback(() => {
    setEditedFiles([]);
    setIsModalOpen(true);
  }, [setEditedFiles]);

  return (
    <ToolContainer>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* Header */}
        <Box sx={{ textAlign: 'center' }}>
          <InfoOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />
          <Typography variant="h6" gutterBottom align="center">
            DICOM Header Viewer/Editor
          </Typography>
        </Box>

        {/* Description */}
        <Typography variant="body2" sx={{ mb: 2, flex: 1 }}>
          View and Edit DICOM header values for all uploaded files.
        </Typography>

        {/* Buttons */}
        <Box sx={{ mt: 'auto' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
            disabled={isProcessing || originalFiles.length === 0}
            fullWidth
            sx={{ mb: 1 }}
          >
            View/Edit Headers
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={onDownload}
            disabled={!editedFiles || editedFiles.length === 0}
            fullWidth
          >
            Download Edited Files
          </Button>
        </Box>

        {/* Modal */}
        <DicomHeaderEditorModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          files={originalFiles}
          onEditHeaders={onEditHeaders}
        />
      </Box>
    </ToolContainer>
  );
};

export default DicomHeaderEditorTool;