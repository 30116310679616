import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
  Stack,
  Link,
  ThemeProvider,
  createTheme
} from '@mui/material';

const theme = createTheme();

const TermsOfService = () => {
  const tocItems = [
    { label: 'Welcome', href: '#welcome-to-dicom-pro', emoji: '' },
    { label: 'Key Points', href: '#key-points', emoji: '' },
    { label: 'Terms', href: '#terms-of-service', emoji: '' },
    { label: 'Privacy', href: '#privacy-policy', emoji: '' },
    { label: 'Disclaimer', href: '#disclaimer', emoji: '' },
    { label: 'Changes', href: '#changes-to-this-policy', emoji: '' },
    { label: 'Compliance', href: '#regulatory-compliance', emoji: '' },
    { label: 'Reporting', href: '#reporting-privacy-and-security-issues', emoji: '' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          DICOM Pro: Terms of Service, Privacy Policy, and Disclaimer
        </Typography>

        <Typography variant="body2" gutterBottom>
          Last Updated: 8/26/2024
        </Typography>

        <Box sx={{ my: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {tocItems.map((item, index) => (
              <Chip
                key={index}
                label={
                  <span>
                    <span role="img" aria-label={item.label} style={{ marginRight: '4px' }}>{item.emoji}</span>
                    {item.label}
                  </span>
                }
                component="a"
                href={item.href}
                clickable
                size="small"
              />
            ))}
          </Stack>
        </Box>

        <Box id="welcome-to-dicom-pro" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="wave" style={{ marginRight: '0.5em' }}></span>
            Welcome to DICOM Pro
          </Typography>
          <Typography variant="body1">
            Thank you for choosing DICOM Pro! Our service offers free online DICOM file processing,
            including anonymization and image conversion, tailored to meet the needs of medical professionals,
            researchers, and educators. By using DICOM Pro, you agree to these terms. If you do not agree,
            please refrain from using our service.
          </Typography>
        </Box>

        <Box id="key-points" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="key" style={{ marginRight: '0.5em' }}></span>
            Key Points
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Educational and Research Purposes"
                secondary="DICOM Pro is primarily intended for educational and research purposes. Although we maintain high standards of data protection, users should exercise caution when considering use for clinical or diagnostic purposes."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Client-Side Processing"
                secondary="All file processing occurs directly in your browser, meaning DICOM Pro does not store your original or processed files on our servers."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="User Responsibility"
                secondary="You are responsible for the files you process and any sharing of these files. It is your responsibility to ensure that all sensitive information has been anonymized before sharing or further use."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Compliance"
                secondary="DICOM Pro is designed to align with GDPR (EU) and HIPAA (USA) guidelines. However, it is your responsibility to ensure your own compliance when handling protected health information."
              />
            </ListItem>
          </List>
        </Box>

        <Box id="terms-of-service" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="clipboard" style={{ marginRight: '0.5em' }}></span>
            Terms of Service
          </Typography>
          <Typography variant="h5" gutterBottom>User Responsibilities</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Data Integrity and Privacy"
                secondary={
                  <React.Fragment>
                    <Typography variant="body2" component="span">
                      - You agree not to upload files containing unprotected personal health information (PHI) or personally identifiable information (PII) without appropriate anonymization.
                    </Typography>
                    <br />
                    <Typography variant="body2" component="span">
                      - You are responsible for ensuring that files processed through DICOM Pro are de-identified before sharing or using outside of secure environments.
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Ethical Use"
                secondary="You agree to use DICOM Pro in a legal, ethical, and responsible manner. Attempts to reverse our anonymization process or use the service for malicious purposes are strictly prohibited."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Compliance"
                secondary={
                  <React.Fragment>
                    <Typography variant="body2" component="span">
                      - If you are a covered entity or business associate under HIPAA, it is your responsibility to ensure your use of DICOM Pro complies with HIPAA regulations.
                    </Typography>
                    <br />
                    <Typography variant="body2" component="span">
                      - Users in the EU or those subject to GDPR must ensure their use of DICOM Pro aligns with GDPR requirements.
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>

          <Typography variant="h5" gutterBottom>Provision of Service</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Service Availability"
                secondary="DICOM Pro is provided 'as is' and 'as available' without any express or implied warranties. We do not guarantee uninterrupted or error-free service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Modifications"
                secondary="We reserve the right to modify, suspend, or discontinue any aspect of the service without prior notice. New features may be introduced or limits imposed at our discretion."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Liability Limitation"
                secondary="DICOM Pro is not liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of the service. This includes data loss, profit loss, or business interruption."
              />
            </ListItem>
          </List>

          <Typography variant="h5" gutterBottom>Intellectual Property</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Your Content"
                secondary="You retain all rights to the DICOM files you process through DICOM Pro. By using the service, you grant us a limited license to process your files solely to provide the service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Our Content"
                secondary="The DICOM Pro platform, including its design, features, and underlying technology, is protected by copyright, trademark, and other laws. You may not copy, modify, or exploit any part of the service without our express permission."
              />
            </ListItem>
          </List>
        </Box>

        <Box id="privacy-policy" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="lock" style={{ marginRight: '0.5em' }}></span>
            Privacy Policy
          </Typography>
          <Typography variant="h5" gutterBottom>Data We Do Not Collect or Store</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="File Content"
                secondary="We do not collect, store, or have access to the contents of your DICOM files. All file processing happens directly within your browser."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Personal Information"
                secondary="We do not extract or store any personal information from the DICOM files you process."
              />
            </ListItem>
          </List>

          <Typography variant="h5" gutterBottom>Data We Do Collect</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Usage Data"
                secondary="We collect anonymized data about how you use the platform, such as feature usage and processing times, to help improve the service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Technical Information"
                secondary="We collect technical data about your device and browser to ensure compatibility and optimize the service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Network Data"
                secondary="We collect IP addresses for security purposes and to prevent service abuse."
              />
            </ListItem>
          </List>

          {/* Continue with other sections of the Privacy Policy... */}
        </Box>

        <Box id="disclaimer" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="warning" style={{ marginRight: '0.5em' }}></span>
            Disclaimer
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Intended Use"
                secondary="DICOM Pro is primarily intended for educational and research purposes. It is not designed for clinical diagnosis or direct patient care."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Anonymization Limitations"
                secondary="While we provide tools to anonymize DICOM tags, users are responsible for verifying that all sensitive information has been removed before sharing files."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="No Medical Advice"
                secondary="DICOM Pro does not provide medical advice. All decisions regarding patient care and diagnosis are solely your responsibility."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Accuracy"
                secondary="While we strive for accuracy, we do not guarantee the completeness or reliability of our service for any specific purpose."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Use at Your Own Risk"
                secondary="You use DICOM Pro at your own risk. We are not liable for any consequences that may arise from the use of the service."
              />
            </ListItem>
          </List>
        </Box>

        <Box id="changes-to-this-policy" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="megaphone" style={{ marginRight: '0.5em' }}></span>
            Changes to This Policy
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Policy Updates"
                secondary="We may update these terms periodically. Significant changes will be communicated through our website at least 30 days before they take effect. We encourage you to review this page regularly."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Contact Us"
                secondary={
                  <Typography variant="body2">
                    For questions or concerns regarding these terms or our service, please contact us at{' '}
                    <Link href="mailto:support@dicompro.com">support@dicompro.com</Link>.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>

        <Box id="regulatory-compliance" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="globe" style={{ marginRight: '0.5em' }}></span>
            Regulatory Compliance
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="GDPR Compliance"
                secondary="DICOM Pro adheres to the principles of the EU General Data Protection Regulation (GDPR). We implement appropriate measures such as encryption and data minimization to ensure compliance."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="HIPAA Compliance"
                secondary="DICOM Pro implements security measures aligned with HIPAA, but users must ensure their compliance when handling protected health information. If needed, contact us about Business Associate Agreements (BAAs)."
              />
            </ListItem>
          </List>
        </Box>

        <Box id="reporting-privacy-and-security-issues" sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            <span role="img" aria-label="siren" style={{ marginRight: '0.5em' }}></span>
            Reporting Privacy and Security Issues
          </Typography>
          <Typography variant="body1">
            If you encounter any privacy or security issues while using DICOM Pro, please contact our Data Protection Officer at{' '}
            <Link href="mailto:support@dicompro.com">support@dicompro.com</Link>. We are committed to resolving any concerns as quickly as possible.
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body2">
            By using DICOM Pro, you acknowledge that you have read, understood, and agree to these terms.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TermsOfService;