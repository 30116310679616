import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
  Link as MuiLink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  MAX_UPLOADS_FREE,
  MAX_FILE_SIZE_FREE,
  RESET_TIME_FREE,
  UPLOAD_COUNT_KEY,
} from '../utils/constants';
import FileUploader from '../features/upload/FileUploader';
import AnonymizationTool from '../features/anonymization/AnonymizationTool';
import ImageConversionTool from '../features/conversion/ImageConversionTool';
import VideoConversionTool from '../features/conversion/VideoConversionTool';
import DicomHeaderEditorTool from '../features/editor/DicomHeaderEditorTool';
import DecompressTool from '../features/decompress/DecompressTool';
import { getTransferSyntaxName } from '../utils/transferSyntax';
import { decompressDicom } from '../features/decompress/decompress';
import { anonymizeDicom } from '../features/anonymization/anonymizer';
import { convertDicomToImage } from '../features/conversion/dicomToImageConverter';
import {
  processFiles,
  formatSize,
  createAndDownloadZip,
} from '../utils/fileUtils';
import DicomViewer from '../features/ui/DicomViewer';
import DicomFileBrowser from '../features/ui/DicomFileBrowser';
import WhyChooseDICOMPro from '../features/ui/WhyChooseDICOMPro';
import HowToEditDicomImages from '../features/ui/HowToEditDicomImages';

// Component to display the remaining uploads for free users
const UploadLimitMessage = ({ remainingUploads }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
    <Alert
      severity="info"
      icon={<WarningAmberIcon />}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        '& .MuiAlert-icon': { marginRight: 1 },
        '& .MuiAlert-message': { padding: 0 },
      }}
    >
      <Typography variant="body2" component="span">
        <strong>{remainingUploads}</strong> of <strong>{MAX_UPLOADS_FREE}</strong> free uploads remaining.
        Max file size: <strong>{formatSize(MAX_FILE_SIZE_FREE)}</strong>.
        {' '}
        <MuiLink
          component={Link}
          to="/signup"
          underline="hover"
          sx={{ fontWeight: 'bold' }}
        >
          Upgrade to Pro
        </MuiLink>
        {' '}for unlimited uploads.
      </Typography>
    </Alert>
  </Box>
);

const HomePage = () => {
  // State variables to manage files, processing state, and notifications
  const [files, setFiles] = useState([]);
  const [dcmCount, setDcmCount] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [anonymizedFiles, setAnonymizedFiles] = useState([]);
  const [convertedImages, setConvertedImages] = useState([]);
  const [anonymizedFilesReady, setAnonymizedFilesReady] = useState(false);
  const [convertedImagesReady, setConvertedImagesReady] = useState(false);
  const [convertedVideos, setConvertedVideos] = useState([]);
  const [editedFiles, setEditedFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [decompressedFiles, setDecompressedFiles] = useState([]);
  const [decompressedFilesReady, setDecompressedFilesReady] = useState(false);
  const [transferSyntax, setTransferSyntax] = useState('');
  const [conversionFormat, setConversionFormat] = useState('jpeg');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProUser, setIsProUser] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
    code: '',
  });

  // Monitor authentication state using Firebase
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsProUser(!!user); // Set Pro status based on user's authentication state
    });

    return () => unsubscribe();
  }, []);

  // Retrieve current upload count from local storage
  const getUploadCount = () => {
    const data = localStorage.getItem(UPLOAD_COUNT_KEY);
    const now = Date.now();
    if (data) {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
        if (now - parsedData.timestamp > RESET_TIME_FREE) {
          // Reset the count if the reset time has passed
          localStorage.setItem(
            UPLOAD_COUNT_KEY,
            JSON.stringify({ count: 0, timestamp: now })
          );
          return 0;
        } else {
          return parsedData.count;
        }
      } catch (e) {
        // Reset if data is corrupted
        localStorage.removeItem(UPLOAD_COUNT_KEY);
        localStorage.setItem(
          UPLOAD_COUNT_KEY,
          JSON.stringify({ count: 0, timestamp: now })
        );
        return 0;
      }
    } else {
      // Initialize the count if not present
      localStorage.setItem(
        UPLOAD_COUNT_KEY,
        JSON.stringify({ count: 0, timestamp: now })
      );
      return 0;
    }
  };

  // Increment upload count and store it in local storage
  const incrementUploadCount = (num = 1) => {
    const data = localStorage.getItem(UPLOAD_COUNT_KEY);
    let parsedData;
    const now = Date.now();
    if (data) {
      try {
        parsedData = JSON.parse(data);
        if (now - parsedData.timestamp > RESET_TIME_FREE) {
          // Reset the count if the reset time has passed
          parsedData = { count: num, timestamp: now };
        } else {
          parsedData.count += num;
        }
      } catch (e) {
        // Reset if data is corrupted
        parsedData = { count: num, timestamp: now };
      }
    } else {
      parsedData = { count: num, timestamp: now };
    }
    localStorage.setItem(UPLOAD_COUNT_KEY, JSON.stringify(parsedData));
    return parsedData.count;
  };

  // Show notification message to the user
  const showNotification = (message, severity, code) => {
    setNotification({ open: true, message, severity, code });
  };

  // Check if user is allowed to upload files based on their account status (Pro/Free)
const canUploadFiles = (selectedFiles) => {
  if (isProUser) {
    return { canUpload: true };
  }

  const uploadCount = getUploadCount();
  if (uploadCount >= MAX_UPLOADS_FREE) {
    return { canUpload: false, code: 'UPLOAD_LIMIT_REACHED' };
  }

  const oversizedFiles = selectedFiles.filter(
    (file) => file.size > MAX_FILE_SIZE_FREE
  );
  if (oversizedFiles.length > 0) {
    return { canUpload: false, code: 'FILE_SIZE_LIMIT_EXCEEDED' };
  }

  const totalSizeSelected = selectedFiles.reduce((acc, file) => acc + file.size, 0);
  if (totalSizeSelected > MAX_FILE_SIZE_FREE) {
    return { canUpload: false, code: 'FILE_SIZE_LIMIT_EXCEEDED' };
  }

  return { canUpload: true };
};

  // Handle file upload and processing
const processSelectedFiles = async (selectedFiles) => {
  setIsUploading(true);

  const { canUpload, code } = canUploadFiles(selectedFiles);

  if (!canUpload) {
    setIsUploading(false);
    let message = '';
    if (code === 'UPLOAD_LIMIT_REACHED') {
      message = `You have reached the maximum upload limit of ${MAX_UPLOADS_FREE} uploads. To upload more files, please log in or`;
    } else if (code === 'FILE_SIZE_LIMIT_EXCEEDED') {
      message = `Some files exceed the maximum file size of ${formatSize(MAX_FILE_SIZE_FREE)}. To upload larger files, please log in or`;
    }
    showNotification(message, 'warning', code);
    return;
  }

    const results = await processFiles(selectedFiles);

    setFiles(results.dcmFiles);
    setDcmCount(results.count);
    setTotalSize(results.size);
    setTransferSyntax(results.transferSyntax);
  resetState();
  setIsUploading(false);

  if (!isProUser) {
    incrementUploadCount(1); // Increment by one per upload action
  }
};


  // Handlers for file selection
  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    processSelectedFiles(selectedFiles);
  };

  const handleFolderSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    processSelectedFiles(selectedFiles);
  };

  // Reset component state after processing
const resetState = () => {
  setAnonymizedFiles([]);
  setConvertedImages([]);
  setConvertedVideos([]);
  setEditedFiles([]);
  setDecompressedFiles([]);
  setError(null);
  setAnonymizedFilesReady(false);
  setConvertedImagesReady(false);
  setDecompressedFilesReady(false);
};

  // Anonymize selected files
  const handleAnonymize = async (settings) => {
    setIsProcessing(true);
    setError(null);
    const processedFiles = [];

    for (let { file, path } of files) {
      try {
        const anonymizedData = await anonymizeDicom(file, settings);
        processedFiles.push({ name: path, data: anonymizedData });
      } catch (err) {
        console.error(`Error processing ${file.name}:`, err);
      }
    }

    setAnonymizedFiles(processedFiles);
    setAnonymizedFilesReady(true);
    setIsProcessing(false);
  };

  // Convert DICOM files to images
  const handleConvert = async () => {
    setIsProcessing(true);
    setError(null);
    const convertedFiles = [];
    let errorOccurred = false;

    for (let { file, path } of files) {
      try {
        const imageDataUrl = await convertDicomToImage(file, conversionFormat);
        convertedFiles.push({
          name: `${path.replace('.dcm', '')}.${conversionFormat}`,
          data: imageDataUrl,
        });
      } catch (err) {
        console.error(`Error converting ${file.name}:`, err);
        errorOccurred = true;
      }
    }

    if (convertedFiles.length > 0) {
      setConvertedImages(convertedFiles);
      setConvertedImagesReady(true);
      if (errorOccurred) {
        setError(
          `Conversion completed with some errors. ${convertedFiles.length} out of ${files.length} files were successfully converted.`
        );
      }
    } else {
      setError('No files were successfully converted. Please check the console for detailed error messages.');
    }
    setIsProcessing(false);
  };

  const handleDecompress = async () => {
  setIsProcessing(true);
  setError(null);
  const processedFiles = [];

  for (let { file, path } of files) {
    try {
      const decompressedData = await decompressDicom(file);
      processedFiles.push({ name: path, data: decompressedData });
    } catch (err) {
      console.error(`Error processing ${file.name}:`, err);
    }
  }

  setDecompressedFiles(processedFiles);
  setDecompressedFilesReady(true);
  setIsProcessing(false);
};


  // Download processed files as a ZIP
const handleDownload = (zipName) => {
  const filesToDownload =
    zipName === 'anonymized_dicom_files'
      ? anonymizedFiles
      : zipName === 'converted_videos'
      ? convertedVideos
      : zipName === 'decompressed_dicom_files'
      ? decompressedFiles
      : convertedImages;

  if (filesToDownload.length === 0) {
    setError(`No ${zipName} files available for download.`);
    return;
  }
  createAndDownloadZip(filesToDownload, zipName, conversionFormat);
};

  // Handle file selection from DICOM File Browser
  const handleFileSelectionFromBrowser = (fileObj) => {
    if (fileObj.file instanceof File) {
      setSelectedFile(fileObj.file);
    } else if (fileObj instanceof File) {
      setSelectedFile(fileObj);
    } else {
      console.error('Invalid file selected:', fileObj);
    }
  };

  // Disable upload buttons if limit is reached
  const uploadLimitReached = !isProUser && getUploadCount() >= MAX_UPLOADS_FREE;
  const fileSizeLimitReached = !isProUser && totalSize > MAX_FILE_SIZE_FREE;

  // Compute remaining uploads for free users
  const uploadCount = getUploadCount();
  const remainingUploads = MAX_UPLOADS_FREE - uploadCount;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Simple DICOM Image Processing
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
Effortlessly upload and process DICOM files with our powerful tools. Anonymize sensitive patient data, convert DICOM to popular formats like JPG and MP4, and edit DICOM headers—all within your browser. No downloads, no installations, just fast and secure medical image processing from any device.        </Typography>
        <Box sx={{ mb: 4 }}>
          <FileUploader
            onFileSelect={handleFileSelect}
            onFolderSelect={handleFolderSelect}
            disabled={uploadLimitReached || fileSizeLimitReached}
          />

          {/* Display Uploads Remaining Counter for Free Users */}
          {!isProUser && <UploadLimitMessage remainingUploads={remainingUploads} />}

          {/* Notification Snackbar */}
          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={() => setNotification({ ...notification, open: false })}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setNotification({ ...notification, open: false })}
              severity={notification.severity}
              sx={{ width: '100%' }}
            >
              {notification.message}{' '}
              {(notification.code === 'UPLOAD_LIMIT_REACHED' ||
                notification.code === 'FILE_SIZE_LIMIT_EXCEEDED') && (
                <>
                  {' '}
                  <MuiLink component={Link} to="/signup" underline="hover">
                    sign up
                  </MuiLink>{' '}
                  to become a Pro user.
                </>
              )}
            </Alert>
          </Snackbar>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
        {dcmCount > 0 && (
          <>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }} align="center">
                Selected: {dcmCount} DICOM file(s)
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }} align="center">
                Total Size: {formatSize(totalSize)}
              </Typography>
                          {transferSyntax && (
              <Typography variant="body2" sx={{ mb: 2 }} align="center">
                Transfer Syntax: {getTransferSyntaxName(transferSyntax)}
              </Typography>
                      )}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
              <DicomViewer files={files} selectedFile={selectedFile} />
              <DicomFileBrowser files={files} onFileSelect={handleFileSelectionFromBrowser} />
            </Box>
          </>
        )}

        <Grid container spacing={3} sx={{ mt: 4, mb:6 }}>
          <Grid item xs={12} md={4}>
            <AnonymizationTool
              files={files}
              isProcessing={isProcessing}
              onAnonymize={handleAnonymize}
              onDownload={handleDownload}
              anonymizedFilesReady={anonymizedFilesReady}
              disabled={!isProUser && dcmCount === 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ImageConversionTool
              files={files}
              isProcessing={isProcessing}
              conversionFormat={conversionFormat}
              setConversionFormat={setConversionFormat}
              onConvert={handleConvert}
              onDownload={handleDownload}
              convertedImagesReady={convertedImagesReady}
              disabled={!isProUser && dcmCount === 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <VideoConversionTool
              files={files}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              setError={setError}
              convertedVideos={convertedVideos}
              setConvertedVideos={setConvertedVideos}
              disabled={!isProUser && dcmCount === 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DicomHeaderEditorTool
              files={files}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              setError={setError}
              editedFiles={editedFiles}
              setEditedFiles={setEditedFiles}
              disabled={!isProUser && dcmCount === 0}
            />
          </Grid>
            <Grid item xs={12} md={4}>
              <DecompressTool
                files={files}
                isProcessing={isProcessing}
                onDecompress={handleDecompress}
                onDownload={handleDownload}
                decompressedFilesReady={decompressedFilesReady}
                disabled={!isProUser && dcmCount === 0}
              />
            </Grid>
        </Grid>
      </Container>

      <div>
                {/* Why Choose DICOM Pro Section */}
        <WhyChooseDICOMPro />
      </div>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {/* How to Edit DICOM Images Online for Free Section */}
        <HowToEditDicomImages />
      </Container>

      {/* Backdrop and Error Message */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProcessing || isUploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {error && (
        <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default HomePage;
