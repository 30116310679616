import { createAndDownloadZip } from '../../utils/fileUtils';

// Function to edit a single DICOM file
export const editDicomFile = async (fileObj, editedHeaders) => {
  const dcmjs = await import('dcmjs');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const dicomData = dcmjs.data.DicomMessage.readFile(event.target.result);
        const elements = dicomData.dict;
        let changesApplied = false;

        // Apply edits to DICOM headers
        Object.entries(editedHeaders).forEach(([tag, newValue]) => {
          const numericTag = tag.replace(/[(),]/g, '');
          if (elements[numericTag]) {
            if (elements[numericTag].Value[0] !== newValue) {
              elements[numericTag].Value = [newValue];
              changesApplied = true;
            }
          } else {
            const tagInfo = dcmjs.data.DicomMetaDictionary.dictionary[tag];
            if (tagInfo) {
              elements[numericTag] = { vr: tagInfo.vr, Value: [newValue] };
              changesApplied = true;
            }
          }
        });

        if (changesApplied) {
          const editedBuffer = dicomData.write({ allowInvalidVRLength: true });
          const editedBlob = new Blob([editedBuffer], { type: 'application/dicom' });
          resolve({ name: fileObj.path, data: editedBlob, changed: true });
        } else {
          resolve({ name: fileObj.path, data: fileObj.file, changed: false });
        }
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = () => reject(new Error(`Failed to read file ${fileObj.path}`));
    reader.readAsArrayBuffer(fileObj.file);
  });
};

// Function to handle editing of all DICOM headers
export const handleEditHeaders = async (originalFiles, editedHeaders, setIsProcessing, setError, setEditedFiles) => {
  setIsProcessing(true);
  setError(null);

  try {
    const processedFiles = await Promise.all(originalFiles.map(fileObj => editDicomFile(fileObj, editedHeaders)));
    setEditedFiles(processedFiles.filter(file => file.changed));

    if (processedFiles.length === 0) {
      setError('No files were successfully processed.');
    } else if (processedFiles.length < originalFiles.length) {
      setError(`${processedFiles.length} out of ${originalFiles.length} files were successfully edited.`);
    }
  } catch (error) {
    setError('Failed to edit DICOM headers. Please try again.');
  } finally {
    setIsProcessing(false);
  }
};

// Function to handle download of edited files
export const handleDownload = (editedFiles, setError) => {
  if (!editedFiles || editedFiles.length === 0) {
    setError('No edited files available for download.');
    return;
  }
  createAndDownloadZip(editedFiles, 'edited_dicom_files', 'dcm');
};