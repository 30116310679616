import React from 'react';
import { Container, Grid, Box, Typography, Button, Card, CardContent, CardActions, useTheme, } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link as RouterLink } from 'react-router-dom';
import { MAX_UPLOADS_FREE, MAX_FILE_SIZE_FREE } from '../utils/constants';
import WhyChooseDICOMPro from '../features/ui/WhyChooseDICOMPro';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      `${(MAX_FILE_SIZE_FREE / (1024 * 1024)).toFixed(0)}MB Upload limit`,
      `${MAX_UPLOADS_FREE} Uploads per month`,
      'Basic anonymization',
      'Basic tool access',
    ],
    buttonText: 'Try our Free Tools',
    buttonVariant: 'outlined',
    buttonLink: '/',
  },
  {
    title: 'Pro',
    price: '15',
    description: [
      'Unlimited DICOM uploads',
      'Advanced anonymization',
      'DICOM Header Editor',
      'All conversion formats (PNG, TIFF, GIF, etc)',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    buttonLink: '/signup',
    featured: true,
  },
  {
    title: 'Enterprise',
    price: null,
    description: [
      'Unlimited DICOM uploads',
      'Custom workflows',
      'Dedicated support (24/7 phone and email)',
      'Custom integrations (API access)',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
    buttonLink: '/contact-us',
  },
];

const Pricing = () => {
  const theme = useTheme();

  return (
      <Box sx={{bgcolor: 'background.paper', py: 6 }}>
        <Container maxWidth="md">
          <Typography
              variant="h4"
              component="h1"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{fontWeight: 'bold', mb: 2}}
          >
            Pricing Plans
          </Typography>
          <Typography
              variant="subtitle1"
              align="center"
              color="text.secondary"
              component="p"
              sx={{mb: 4, maxWidth: '600px', mx: 'auto'}}
          >
            Choose a plan that fits your needs. Upgrade or downgrade at any time.
          </Typography>

          <Grid container spacing={3} alignItems="flex-end" justifyContent="center" sx={{mb: 12}}>
            {tiers.map((tier) => (
                <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={tier.title === 'Pro' ? 12 : 6}
                    md={4}
                >
                  <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 2,
                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: 3,
                        },
                        ...(tier.featured && {
                          border: `2px solid ${theme.palette.primary.main}`,
                          boxShadow: 2,
                        }),
                      }}
                  >
                    <CardContent sx={{flexGrow: 1}}>
                      <Box
                          sx={{
                            bgcolor: tier.featured ? 'primary.main' : 'grey.100',
                            color: tier.featured ? 'primary.contrastText' : 'text.primary',
                            py: 2,
                            px: 2,
                            mb: 2,
                            borderRadius: '8px 8px 0 0',
                          }}
                      >
                        <Typography variant="h5" component="h2" align="center" sx={{fontWeight: 'bold', mb: 1}}>
                          {tier.title}
                        </Typography>
                        <Typography variant="h6" align="center">
                          {tier.price !== null ? `$${tier.price}` : 'Contact Sales'}
                          {tier.price !== null && <Typography component="span" variant="body2">/mo</Typography>}
                        </Typography>
                      </Box>
                      <Box sx={{mb: 2}}>
                        {tier.description.map((line) => (
                            <Box key={line} sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                              <CheckCircleOutlineIcon sx={{mr: 1, color: 'success.main', fontSize: '1rem'}}/>
                              <Typography variant="body2">{line}</Typography>
                            </Box>
                        ))}
                      </Box>
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center', pb: 2}}>
                      <Button
                          component={RouterLink}
                          to={tier.buttonLink}
                          fullWidth
                          variant={tier.buttonVariant}
                          color={tier.featured ? 'secondary' : 'primary'}
                          size="medium"
                          sx={{
                            maxWidth: '80%',
                            height: 40,
                            fontSize: '0.875rem',
                          }}
                      >
                        {tier.buttonText}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </Container>

        <div>
          {/* Why Choose DICOM Pro Section */}
          <WhyChooseDICOMPro/>
        </div>

      </Box>
  );
};

export default Pricing;
