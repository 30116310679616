import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Box, Button, Grid, Paper, Avatar, TextField,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  InputAdornment, IconButton, LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Person, Security, CreditCard, Visibility, VisibilityOff } from '@mui/icons-material';
import { auth, getFirebaseErrorMessage } from '../firebase';  // Import Firebase auth and error handling
import { updatePassword, updateEmail, reauthenticateWithCredential, EmailAuthProvider, deleteUser } from 'firebase/auth';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.9rem',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.9rem',
  },
}));

const AccountDashboard = () => {
  const [user, setUser] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setNewEmail(currentUser.email);
        setConfirmNewEmail(currentUser.email);
      }
    });
    return () => unsubscribe();
  }, []);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const calculatePasswordStrength = (password) => {
    if (password.length === 0) return 0;
    if (password.length < 6) return 1;
    let strength = 2;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/[0-9]/)) strength++;
    if (password.match(/[^a-zA-Z0-9]/)) strength++;
    return Math.min(strength, 5);
  };

  const getPasswordStrengthLabel = (strength) => {
    if (strength === 0) return '';
    if (strength === 1) return 'Too weak';
    if (strength === 2) return 'Could be stronger';
    if (strength === 3) return 'Decent';
    if (strength === 4) return 'Strong';
    return 'Very strong';
  };

  const handleEmailChange = async (e) => {
    e.preventDefault();
    setEmailError('');
    setConfirmEmailError('');
    setGeneralError('');

    if (!validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (newEmail !== confirmNewEmail) {
      setConfirmEmailError('Email addresses do not match.');
      return;
    }

    try {
      await updateEmail(user, newEmail);
      alert('Email updated successfully!');
    } catch (error) {
      setGeneralError(getFirebaseErrorMessage(error.code));
      console.error('Error updating email:', error);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setGeneralError('');

    if (!validatePassword(newPassword)) {
      setNewPasswordError('Password must be at least 6 characters long.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      alert('Password updated successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setGeneralError(getFirebaseErrorMessage(error.code));
      console.error('Error updating password:', error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUser(user);
      alert('Your account has been deleted successfully.');
      // Redirect to home page or sign up page
      window.location.href = '/';
    } catch (error) {
      setGeneralError(getFirebaseErrorMessage(error.code));
      console.error('Error deleting account:', error);
    }
    setDeleteAccountDialogOpen(false);
  };

  if (!user) {
    return <Typography>Please sign in to view your account dashboard.</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Account Dashboard
      </Typography>

      {generalError && <Typography color="error" sx={{ mt: 2, mb: 2 }}>{generalError}</Typography>}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Person sx={{ mr: 2 }} />
              <Typography variant="h6">Profile Information</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar sx={{ width: 60, height: 60, mr: 2 }}>
                {user.email[0].toUpperCase()}
              </Avatar>
              <Box>
                <Typography variant="body1">{user.email}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Member since: {user.metadata.creationTime}
                </Typography>
              </Box>
            </Box>
            <Box component="form" onSubmit={handleEmailChange}>
              <StyledTextField
                fullWidth
                label="New Email Address"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                margin="normal"
                size="small"
              />
              <StyledTextField
                fullWidth
                label="Confirm New Email Address"
                value={confirmNewEmail}
                onChange={(e) => setConfirmNewEmail(e.target.value)}
                error={!!confirmEmailError}
                helperText={confirmEmailError}
                margin="normal"
                size="small"
              />
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                Update Email
              </Button>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Security sx={{ mr: 2 }} />
              <Typography variant="h6">Security Settings</Typography>
            </Box>
            <Typography variant="body2" paragraph>
              Regularly updating your password is crucial for maintaining the security of your account.
            </Typography>
            <Box component="form" onSubmit={handlePasswordChange}>
              <StyledTextField
                fullWidth
                label="Current Password"
                type={showCurrentPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                error={!!currentPasswordError}
                helperText={currentPasswordError}
                margin="normal"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        edge="end"
                        size="small"
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                fullWidth
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!!newPasswordError}
                helperText={newPasswordError}
                margin="normal"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                        size="small"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {newPassword.length > 0 && (
                <>
                  <LinearProgress
                    variant="determinate"
                    value={(calculatePasswordStrength(newPassword) / 5) * 100}
                    sx={{ mt: 1, height: 5, borderRadius: 5 }}
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>
                    Password strength: {getPasswordStrengthLabel(calculatePasswordStrength(newPassword))}
                    {calculatePasswordStrength(newPassword) < 2 && " - Must be at least 6 characters"}
                  </Typography>
                </>
              )}
              <StyledTextField
                fullWidth
                label="Confirm New Password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                margin="normal"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                        size="small"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                Change Password
              </Button>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <CreditCard sx={{ mr: 2 }} />
              <Typography variant="h6">Subscription Management</Typography>
            </Box>
            <Typography variant="body2" paragraph>
              To manage your subscription, view billing history, or update payment information, you'll be redirected to our secure Stripe Customer Portal.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component="a"
              href="https://billing.stripe.com/p/login/9AQaHw90q3C61Z6fYY"
              target="_blank"
              rel="noopener noreferrer"
            >
              Manage Subscription
            </Button>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>Delete Account</Typography>
            <Typography variant="body2" paragraph>
              Deleting your account is permanent and cannot be undone. Be sure to cancel your subscription first.
            </Typography>
            <Button variant="contained" color="error" onClick={() => setDeleteAccountDialogOpen(true)}>
              Delete Account
            </Button>
          </StyledPaper>
        </Grid>
      </Grid>

      <Dialog
        open={deleteAccountDialogOpen}
        onClose={() => setDeleteAccountDialogOpen(false)}
      >
        <DialogTitle>Are you sure you want to delete your account?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone. All of your data will be permanently deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAccountDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AccountDashboard;