import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

import Header from './features/ui/Header';
import Footer from './features/ui/Footer';
import HomePage from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import TermsOfService from './pages/TermsOfService';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import Pricing from './pages/Pricing';
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import AccountDashboard from './pages/AccountDashboard';


const theme = createTheme();

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
          <Header />
          <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}> {/* Add overflow: 'auto' */}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<CancelPage />} />
              <Route path="/account" element={<AccountDashboard />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
        <SpeedInsights />
        <Analytics />
      </ThemeProvider>
    </Router>
  );
};

export default App;
