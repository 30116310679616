import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import * as dcmjs from 'dcmjs';

const DicomHeaderEditorModal = ({ open, handleClose, files, onEditHeaders }) => {
  const [headers, setHeaders] = useState([]);
  const [editedHeaders, setEditedHeaders] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const formatTag = (tag) => {
    if (tag.match(/^\(\d{4},\d{4}\)$/)) {
      return tag;
    }
    return tag.length === 8 ? `(${tag.slice(0,4)},${tag.slice(4)})` : tag;
  };

  const getTagName = useMemo(() => (tag) => {
    const formattedTag = formatTag(tag);
    return dcmjs.data.DicomMetaDictionary.dictionary[formattedTag]?.name || 'Unknown';
  }, []);

const formatDicomValue = useMemo(() => (value) => {
  if (value === undefined || value === null) return '';
  if (Array.isArray(value)) return value.join('\\');
  if (typeof value === 'object') {
    if (value.vr === 'SQ') return `Sequence with ${value.Value.length} item(s)`;
    if (value.Value) return formatDicomValue(value.Value);
    return JSON.stringify(value);
  }
  // Replace null characters without using a regex
  return String(value).split('\0').join('');
}, []);

  const readDicomHeaders = useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const dicomData = dcmjs.data.DicomMessage.readFile(event.target.result);
          const headers = Object.entries(dicomData.dict).map(([tag, data]) => ({
            tag: formatTag(tag),
            name: getTagName(tag),
            vr: data.vr,
            value: formatDicomValue(data.Value),
          }));
          resolve(headers);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsArrayBuffer(file);
    });
  }, [formatDicomValue, getTagName]);

  useEffect(() => {
    if (open) {
      setEditedHeaders({});
      if (files.length > 0) {
        readDicomHeaders(files[0].file).then(setHeaders).catch(console.error);
      } else {
        setHeaders([]);
      }
    }
  }, [open, files, readDicomHeaders]);

  const filteredHeaders = useMemo(() =>
    headers.filter(header =>
      Object.values(header).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ),
    [headers, searchTerm]
  );

  const handleEdit = useCallback((tag, newValue) => {
    setEditedHeaders(prev => ({ ...prev, [formatTag(tag)]: newValue }));
  }, []);

  const handleApply = useCallback(() => {
    onEditHeaders(editedHeaders);
    handleClose();
  }, [editedHeaders, onEditHeaders, handleClose]);

  // Memoized TextField component for editing headers
  const EditTextField = useMemo(() => React.memo(({ tag, value, onChange }) => (
    <TextField
      size="small"
      fullWidth
      value={value || ''}
      onChange={(e) => onChange(tag, e.target.value)}
      inputProps={{ style: { fontSize: '0.8rem', padding: '4px' } }}
    />
  )), []);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="dicom-header-editor-modal">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: 1200,
        maxHeight: '95vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
      }}>
        {/* Close button */}
        <Button onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </Button>

        {/* Modal title */}
        <Typography variant="h6" component="h2" gutterBottom>
          Edit DICOM Headers
        </Typography>

        {/* Search input */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
          <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField
            label="Search headers"
            variant="standard"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Box>

      {/* DICOM headers table */}
        <TableContainer component={Paper} sx={{ maxHeight: 'calc(95vh - 250px)' }}>
          <Table stickyHeader aria-label="dicom header editor table" size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '15%', fontWeight: 'bold', fontSize: '0.8rem', padding: '6px' }}>Tag</TableCell>
                <TableCell sx={{ width: '25%', fontWeight: 'bold', fontSize: '0.8rem', padding: '6px' }}>Name</TableCell>
                <TableCell sx={{ width: '5%', fontWeight: 'bold', fontSize: '0.8rem', padding: '6px' }}>VR</TableCell>
                <TableCell sx={{ width: '25%', fontWeight: 'bold', fontSize: '0.8rem', padding: '6px' }}>Current Value</TableCell>
                <TableCell sx={{ width: '30%', fontWeight: 'bold', fontSize: '0.8rem', padding: '6px' }}>New Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHeaders.map((header, index) => (
                <TableRow
                  key={header.tag}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5'
                  }}
                >
                  <TableCell sx={{ fontSize: '0.8rem', padding: '6px' }}>{header.tag}</TableCell>
                  <TableCell sx={{ fontSize: '0.8rem', padding: '6px' }}>{header.name}</TableCell>
                  <TableCell sx={{ fontSize: '0.8rem', padding: '6px' }}>{header.vr}</TableCell>
                  <TableCell sx={{ fontSize: '0.8rem', padding: '6px' }}>{header.value}</TableCell>
                  <TableCell sx={{ padding: '6px' }}>
                    <EditTextField
                      tag={header.tag}
                      value={editedHeaders[header.tag]}
                      onChange={handleEdit}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Action buttons */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
          <Button onClick={handleApply} variant="contained" color="primary">Apply Changes</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DicomHeaderEditorModal;
