import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const FileUploader = ({ onFileSelect, onFolderSelect, disabled }) => (
  <Paper
    elevation={3}
    sx={{
      p: 4,
      borderRadius: 2,
      backgroundColor: (theme) => theme.palette.grey[50],
      border: '2px dashed',
      borderColor: (theme) => theme.palette.grey[300],
      transition: 'all 0.3s ease',
      '&:hover': {
        borderColor: (theme) => theme.palette.primary.main,
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <CloudUploadIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
      <Typography variant="h6" gutterBottom>
        Drag & Drop your files here
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        or use the buttons below to select files
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <input
          type="file"
          onChange={onFileSelect}
          accept=".dcm,.zip"
          style={{ display: 'none' }}
          id="file-input"
          multiple
          disabled={disabled}
        />
        <label htmlFor="file-input">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            disabled={disabled}
          >
            Select Files
          </Button>
        </label>
        <input
          type="file"
          onChange={onFolderSelect}
          style={{ display: 'none' }}
          id="folder-input"
          webkitdirectory=""
          directory=""
          disabled={disabled}
        />
        <label htmlFor="folder-input">
          <Button
            variant="outlined"
            component="span"
            startIcon={<FolderOpenIcon />}
            disabled={disabled}
          >
            Select Folder
          </Button>
        </label>
      </Box>
      <Typography variant="caption" sx={{ mt: 2, color: 'text.secondary' }}>
        Supported files: .zip, .dcm, or folder containing .dcm files
      </Typography>
    </Box>
  </Paper>
);

export default FileUploader;