import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';  // Import Firebase auth configuration
import { signOut, onAuthStateChanged } from 'firebase/auth';  // Import Firebase auth functions

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      window.location.href = '/signin';
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Tools" />
        </ListItem>
        <ListItem button component={Link} to="/pricing">
          <ListItemText primary="Pricing" />
        </ListItem>
        {user && (
          <ListItem button component={Link} to="/account">
            <ListItemText primary="Account" />
          </ListItem>
        )}
        {user ? (
          <ListItem button onClick={handleSignOut}>
            <ListItemText primary="Sign Out" />
          </ListItem>
        ) : (
          <>
            <ListItem button component={Link} to="/signin">
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem button component={Link} to="/signup">
              <ListItemText primary="Sign Up" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: '#f5f5f5', color: 'black', boxShadow: 'none' }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
        >
          DICOM Pro
        </Typography>

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button color="inherit" component={Link} to="/">Tools</Button>
          <Button color="inherit" component={Link} to="/pricing">Pricing</Button>
          {user && (
            <Button color="inherit" component={Link} to="/account">Account</Button>
          )}
          {user ? (
            <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
          ) : (
            <>
              <Button color="inherit" component={Link} to="/signin">Login</Button>
              <Button variant="contained" color="primary" component={Link} to="/signup">Sign Up</Button>
            </>
          )}
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;