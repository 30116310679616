import React, { useState } from 'react';
import { Typography, Button, Box, FormControl, Select, MenuItem } from '@mui/material';
import { ToolContainer } from '../ui/StyledComponents';
import { convertDicomToVideo } from './dicomToVideoConverter';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

const VideoConversionTool = ({ files, isProcessing, setIsProcessing, setError, convertedVideos, setConvertedVideos }) => {
  const [videoFormat, setVideoFormat] = useState('mp4');
  const [frameRate, setFrameRate] = useState(24);
  const [resolution, setResolution] = useState('480p');

  const handleConvert = async () => {
    if (files.length === 0) {
      setError('No files selected for conversion');
      return;
    }

    setIsProcessing(true);
    setError(null);
    setConvertedVideos([]);

    console.log('Files to convert:', files);

    try {
      const result = await convertDicomToVideo(files.map(f => f.file), {
        format: videoFormat,
        frameRate,
        resolution,
      });
      if (result.success) {
        setConvertedVideos([result.video]);
      } else {
        console.error('Error converting files:', result.error);
        setError(`Error converting files: ${result.error}`);
      }
    } catch (error) {
      console.error('Error in video conversion:', error);
      setError('An unexpected error occurred during video conversion');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDownload = () => {
    convertedVideos.forEach(video => {
      const a = document.createElement('a');
      a.href = video.url;
      a.download = video.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <ToolContainer>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* Icon and Title */}
        <Box sx={{ textAlign: 'center' }}>
          <VideoLibraryOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} /> {/* Add the VideoCameraBackIcon */}
          <Typography variant="h6" gutterBottom align="center">
            Video Conversion Tool
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ mb: 2, flex: 1 }}>
          Convert DICOM files to video formats like MP4 or GIF for easy viewing and sharing.
        </Typography>

        {/* Resolution and Frame Rate Selectors */}
        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <FormControl variant="outlined" size="small" sx={{ width: '48%' }}>
              <Typography variant="caption">Resolution</Typography>
              <Select
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
              >
                <MenuItem value="1080p">1080p</MenuItem>
                <MenuItem value="720p">720p</MenuItem>
                <MenuItem value="480p">480p</MenuItem>
                <MenuItem value="360p">360p</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ width: '48%' }}>
              <Typography variant="caption">Frame Rate</Typography>
              <Select
                value={frameRate}
                onChange={(e) => setFrameRate(e.target.value)}
              >
                <MenuItem value={5}>5 fps</MenuItem>
                <MenuItem value={10}>10 fps</MenuItem>
                <MenuItem value={15}>15 fps</MenuItem>
                <MenuItem value={24}>24 fps</MenuItem>
                <MenuItem value={30}>30 fps</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Convert To Button */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleConvert}
              disabled={isProcessing || files.length === 0}
              sx={{ mr: 1, flexGrow: 1 }}
            >
              Convert To
            </Button>
            <FormControl variant="outlined" size="small" sx={{ width: '30%' }}>
              <Select
                value={videoFormat}
                onChange={(e) => setVideoFormat(e.target.value)}
              >
                <MenuItem value="mp4">MP4</MenuItem>
                <MenuItem value="gif">GIF</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Download Button */}
          <Button
            variant="contained"
            color="success"
            onClick={handleDownload}
            disabled={convertedVideos.length === 0}
            fullWidth
          >
            Download Converted Video
          </Button>
        </Box>
      </Box>
    </ToolContainer>
  );
};

export default VideoConversionTool;