import React from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Settings as SettingsIcon,
  CheckCircle as CheckCircleIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';

const HowToEditDicomImages = () => {
  const steps = [
    {
      icon: <CloudUploadIcon />,
      text: "Upload your DICOM file(s) into any of our tools using the upload button.",
    },
    {
      icon: <SettingsIcon />,
      text: "Select the desired tools, such as anonymization, conversion, or editing.",
    },
    {
      icon: <CheckCircleIcon />,
      text: "Click the \"Process\" button to apply your changes.",
    },
    {
      icon: <GetAppIcon />,
      text: "Once processing is complete, download your modified DICOM files in your desired format.",
    },
  ];

  return (
    <Box sx={{ mt: 8 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4 }}>
        How to Edit DICOM Images Online For Free
      </Typography>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="body1" gutterBottom>
          To edit your DICOM images using our tools, simply follow these steps:
        </Typography>
        <List>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemIcon sx={{ color: 'primary.main' }}>{step.icon}</ListItemIcon>
                <ListItemText primary={step.text} />
              </ListItem>
              {index < steps.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
        <Typography variant="body1" sx={{ mt: 2 }}>
          DICOM Pro allows you to work with a variety of formats, including converting DICOM files to JPEG, PNG, and MP4 for easier viewing and sharing.
        </Typography>
      </Paper>
    </Box>
  );
};

export default HowToEditDicomImages;
