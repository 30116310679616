import dcmjs from 'dcmjs';
import { ANONYMIZATION_TAGS } from '../../utils/constants';

class DICOMAnonymizer {
  constructor() {
    this.uidMap = new Map();
  }

  async anonymizeDicom(file, settings) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          let dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer);

          if (!dicomData || !dicomData.dict) {
            throw new Error("Invalid DICOM data structure");
          }

          this.anonymizeDataset(dicomData.dict, settings);
          this.addAnonymizationTags(dicomData.dict);

          let anonymizedBuffer = dicomData.write({ allowInvalidVRLength: true });
          const anonymizedBlob = new Blob([anonymizedBuffer], { type: 'application/dicom' });
          resolve(anonymizedBlob);
        } catch (error) {
          console.error("Anonymization failed:", error);
          reject(new Error('Failed to anonymize DICOM file: ' + error.message));
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read file'));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  anonymizeDataset(dataset, settings) {
    ANONYMIZATION_TAGS.forEach(tagInfo => {
      if (settings[tagInfo.group]) {
        const tag = tagInfo.tag.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();
        if (dataset[tag]) {
          this.anonymizeElement(dataset, tag, tagInfo.newValue);
        }
      }
    });

    if (settings['Private Tags']) {
      this.removePrivateTags(dataset);
    }

    // Process sequences recursively
    for (let tag in dataset) {
      const element = dataset[tag];
      if (element && element.vr === 'SQ') {
        this.anonymizeSequence(element, settings);
      }
    }
  }

  anonymizeSequence(sequence, settings) {
    if (sequence.items) {
      sequence.items.forEach(item => {
        this.anonymizeDataset(item.dataSet, settings);
      });
    }
  }

  removePrivateTags(dataset) {
    for (let tag in dataset) {
      if (this.isPrivateTag(tag)) {
        delete dataset[tag];
      }
    }
  }

  isPrivateTag(tag) {
    // Convert tag to uppercase and remove any non-hexadecimal characters
    const cleanTag = tag.toUpperCase().replace(/[^0-9A-F]/g, '');

    // Extract the group number (first 4 characters of the cleaned tag)
    const group = parseInt(cleanTag.slice(0, 4), 16);

    // Check if the group number is odd
    return (group % 2 !== 0) && (group !== 0x0001);
  }

  anonymizeElement(dataset, tag, newValue) {
    const element = dataset[tag];
    switch (newValue) {
      case 'REMOVED':
        delete dataset[tag];
        break;
      case 'ANONYMIZED UID':
        this.anonymizeUID(dataset, tag);
        break;
      case 'ANONYMOUS':
        element.Value = ['ANONYMOUS'];
        break;
      case 'MODIFIED':
        if (this.isDate(element.vr)) {
          element.Value = ['19700101'];
        } else if (this.isTime(element.vr)) {
          element.Value = ['000000.000000'];
        }
        break;
      default:
        if (newValue !== undefined && newValue !== null) {
          element.Value = [newValue];
        }
    }
  }

  anonymizeUID(dataset, tag) {
    const element = dataset[tag];
    const originalUID = element.Value[0];
    if (!this.uidMap.has(originalUID)) {
      this.uidMap.set(originalUID, dcmjs.data.DicomMetaDictionary.uid());
    }
    element.Value = [this.uidMap.get(originalUID)];
  }

  addAnonymizationTags(dataset) {
    dataset['00120062'] = { vr: 'CS', Value: ['YES'] }; // Patient Identity Removed
    dataset['00120063'] = { vr: 'LO', Value: ['DICOM Anonymizer JS'] }; // De-identification Method
  }

  isDate(vr) {
    return ['DA', 'DT'].includes(vr);
  }

  isTime(vr) {
    return ['TM', 'DT'].includes(vr);
  }
}

const anonymizer = new DICOMAnonymizer();

export async function anonymizeDicom(file, selectedGroups) {
  return anonymizer.anonymizeDicom(file, selectedGroups);
}