import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
<Box
  component="footer"
  sx={{
    mt: 'auto',
    py: 3,
    backgroundColor: 'background.paper',
    width: '100%'
  }}
>
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} DICOM Pro.
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          <Link color="inherit" href="/contact-us" sx={{ mx: 1 }}>
            Contact Us
          </Link>
          |
          <Link color="inherit" href="/terms-of-service" sx={{ mx: 1 }}>
            Terms of Service
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;