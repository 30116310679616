import { FFmpeg } from '@ffmpeg/ffmpeg';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

let ffmpeg = null;

export const convertDicomToVideo = async (files, options) => {
  const { format, frameRate, resolution } = options;

  try {
    if (!ffmpeg) {
      ffmpeg = new FFmpeg();
      console.log('Loading FFmpeg...');
      await ffmpeg.load();
      console.log('FFmpeg loaded successfully');
    }

    console.log(`Processing ${files.length} files`);

    // Step 1: Convert DICOM to JPEG
    const jpegFiles = await Promise.all(files.map(async (file, index) => {
      const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
      const image = await cornerstone.loadImage(imageId);
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      cornerstone.renderToCanvas(canvas, image);
      const jpegBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.8));
      const jpegArrayBuffer = await jpegBlob.arrayBuffer();
      const fileName = `image_${index.toString().padStart(5, '0')}.jpg`;
      await ffmpeg.writeFile(fileName, new Uint8Array(jpegArrayBuffer));
      return fileName;
    }));

    console.log(`Converted ${jpegFiles.length} DICOM files to JPEG`);

    // Step 2: Create video from JPEG files
    const outputFileName = `output.${format}`;
    let ffmpegCommand = [
      '-framerate', frameRate.toString(),
      '-pattern_type', 'glob',
      '-i', '*.jpg',
    ];

    // Add resolution filter if not original
    if (resolution !== 'original') {
      const [width, height] = resolutionToPixels(resolution);
      ffmpegCommand = ffmpegCommand.concat(['-vf', `scale=${width}:${height}`]);
    }

    ffmpegCommand = ffmpegCommand.concat([
      '-c:v', format === 'mp4' ? 'libx264' : 'gif',
      '-pix_fmt', format === 'mp4' ? 'yuv420p' : 'rgb8',
      outputFileName
    ]);

    console.log('Executing FFmpeg command:', ffmpegCommand.join(' '));
    await ffmpeg.exec(ffmpegCommand);
    console.log('Video creation completed');

    const data = await ffmpeg.readFile(outputFileName);
    console.log(`Output file read successfully, size: ${data.byteLength}`);

    const blob = new Blob([data.buffer], { type: format === 'mp4' ? 'video/mp4' : 'image/gif' });
    const video = {
      name: `converted_video.${format}`,
      url: URL.createObjectURL(blob),
      size: blob.size,
    };

    console.log('Cleaning up temporary files...');
    for (const file of jpegFiles) {
      await ffmpeg.deleteFile(file);
    }
    await ffmpeg.deleteFile(outputFileName);
    console.log('Temporary files deleted');

    return { success: true, video };
  } catch (error) {
    console.error('Error in convertDicomToVideo:', error);
    return { success: false, error: error.message || 'Unknown error occurred' };
  }
};

function resolutionToPixels(resolution) {
  switch (resolution) {
    case '1080p':
      return ['1920', '1080'];
    case '720p':
      return ['1280', '720'];
    case '480p':
      return ['854', '480'];
    case '360p':
      return ['640', '360'];
    default:
      return ['1280', '720']; // Default to 720p if unknown
  }
}