// src/utils/constants.js

// constants.js
export const MAX_UPLOADS_FREE = 5; // Max uploads allowed for free users
export const MAX_FILE_SIZE_FREE = 100 * 1024 * 1024; // 100MB file size limit for free users
export const RESET_TIME_FREE = 30 * 24 * 60 * 60 * 1000; // Reset time (1 month in milliseconds)
export const UPLOAD_COUNT_KEY = 'uploadCountData'; // Local storage key for upload count data

export const IMAGE_FORMATS = [
  { value: 'jpeg', label: 'JPEG' },
  { value: 'png', label: 'PNG' },
  { value: 'webp', label: 'WebP' },
  { value: 'tiff', label: 'TIFF' },
  { value: 'bmp', label: 'BMP' },
  { value: 'gif', label: 'GIF' },
];


export const ANONYMIZATION_TAGS = [
  // Patient Information
  { tag: '00100010', description: 'Patient\'s Name', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00100020', description: 'Patient ID', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00100021', description: 'Issuer of Patient ID', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00100030', description: 'Patient\'s Birth Date', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00100032', description: 'Patient\'s Birth Time', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00100040', description: 'Patient\'s Sex', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00100050', description: 'Patient\'s Insurance Plan Code Sequence', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00101000', description: 'Other Patient IDs', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00101001', description: 'Other Patient Names', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00101002', description: 'Other Patient IDs Sequence', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00101005', description: 'Patient\'s Birth Name', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00101010', description: 'Patient\'s Age', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00101020', description: 'Patient\'s Size', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00101030', description: 'Patient\'s Weight', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00101040', description: 'Patient\'s Address', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00101060', description: 'Patient\'s Mother\'s Birth Name', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00101080', description: 'Military Rank', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00101081', description: 'Branch of Service', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00101090', description: 'Medical Record Locator', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102000', description: 'Medical Alerts', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102110', description: 'Allergies', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102150', description: 'Country of Residence', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00102152', description: 'Region of Residence', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00102154', description: 'Patient\'s Telephone Numbers', newValue: 'ANONYMOUS', group: 'Patient Information' },
  { tag: '00102160', description: 'Ethnic Group', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102180', description: 'Occupation', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '001021A0', description: 'Smoking Status', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '001021B0', description: 'Additional Patient History', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '001021C0', description: 'Pregnancy Status', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '001021D0', description: 'Last Menstrual Date', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '001021F0', description: 'Patient\'s Religious Preference', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102203', description: 'Patient\'s Sex Neutered', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102297', description: 'Responsible Person', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102298', description: 'Responsible Person Role', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00102299', description: 'Responsible Organization', newValue: 'REMOVED', group: 'Patient Information' },
  { tag: '00104000', description: 'Patient Comments', newValue: 'REMOVED', group: 'Patient Information' },

  // Study and Series Information
  { tag: '0008002A', description: 'Acquisition DateTime', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080020', description: 'Study Date', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080021', description: 'Series Date', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080022', description: 'Acquisition Date', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080023', description: 'Content Date', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080030', description: 'Study Time', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080031', description: 'Series Time', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080032', description: 'Acquisition Time', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080033', description: 'Content Time', newValue: 'MODIFIED', group: 'Study and Series Information' },
  { tag: '00080050', description: 'Accession Number', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00080051', description: 'Issuer of Accession Number Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00080080', description: 'Institution Name', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00080081', description: 'Institution Address', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00080082', description: 'Institution Code Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081010', description: 'Station Name', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081030', description: 'Study Description', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '0008103E', description: 'Series Description', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081040', description: 'Institutional Department Name', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081048', description: 'Physician(s) of Record', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081049', description: 'Physician(s) of Record Identification Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081050', description: 'Performing Physician\'s Name', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081052', description: 'Performing Physician Identification Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081060', description: 'Name of Physician(s) Reading Study', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081062', description: 'Physician(s) Reading Study Identification Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081070', description: 'Operators\' Name', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081072', description: 'Operator Identification Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081080', description: 'Admitting Diagnoses Description', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081084', description: 'Admitting Diagnoses Code Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081110', description: 'Referenced Study Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081111', description: 'Referenced Performed Procedure Step Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00081120', description: 'Referenced Patient Sequence', newValue: 'REMOVED', group: 'Study and Series Information' },
  { tag: '00200010', description: 'Study ID', newValue: 'ANONYMOUS', group: 'Study and Series Information' },
  { tag: '00181030', description: 'Protocol Name', newValue: 'REMOVED', group: 'Study and Series Information' },

  // Institution and Personnel
  { tag: '00080090', description: 'Referring Physician\'s Name', newValue: 'ANONYMOUS', group: 'Institution and Personnel' },
  { tag: '00080092', description: 'Referring Physician\'s Address', newValue: 'REMOVED', group: 'Institution and Personnel' },
  { tag: '00080094', description: 'Referring Physician\'s Telephone Numbers', newValue: 'REMOVED', group: 'Institution and Personnel' },
  { tag: '00080096', description: 'Referring Physician Identification Sequence', newValue: 'REMOVED', group: 'Institution and Personnel' },
  { tag: '0008009C', description: 'Consulting Physician\'s Name', newValue: 'REMOVED', group: 'Institution and Personnel' },
  { tag: '0008009D', description: 'Consulting Physician Identification Sequence', newValue: 'REMOVED', group: 'Institution and Personnel' },

  // Equipment and Procedure
  { tag: '00180010', description: 'Contrast/Bolus Agent', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181000', description: 'Device Serial Number', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181002', description: 'Device UID', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181004', description: 'Plate ID', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181005', description: 'Generator ID', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181007', description: 'Cassette ID', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181008', description: 'Gantry ID', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181030', description: 'Protocol Name', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181400', description: 'Acquisition Device Processing Description', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181401', description: 'Acquisition Device Processing Code', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181403', description: 'Cassette Orientation', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181404', description: 'Cassette Size', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181405', description: 'Exposures on Plate', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181450', description: 'Column Angulation', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181500', description: 'Positioner Motion', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181508', description: 'Positioner Type', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181510', description: 'Positioner Primary Angle', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181511', description: 'Positioner Secondary Angle', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181530', description: 'Detector Primary Angle', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00181531', description: 'Detector Secondary Angle', newValue: 'REMOVED', group: 'Equipment and Procedure' },
  { tag: '00182111', description: 'Derivation Description', newValue: 'REMOVED', group: 'Equipment and Procedure' },

  // UIDs and References
  { tag: '0008010D', description: 'Context Group Extension Creator UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '00080014', description: 'Instance Creator UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '00080018', description: 'SOP Instance UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '00081155', description: 'Referenced SOP Instance UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '0020000D', description: 'Study Instance UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '0020000E', description: 'Series Instance UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '00200052', description: 'Frame of Reference UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '00200200', description: 'Synchronization Frame of Reference UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '0040A124', description: 'UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '00880140', description: 'Storage Media File-set UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '300600C2', description: 'Related Frame of Reference UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '30060024', description: 'Referenced Frame of Reference UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },
  { tag: '0070031A', description: 'Fiducial UID', newValue: 'ANONYMIZED UID', group: 'UIDs and References' },

  // Other
  { tag: '00080024', description: 'Overlay Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00080025', description: 'Curve Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '0008002A', description: 'Acquisition DateTime', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00080034', description: 'Overlay Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00080035', description: 'Curve Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00080201', description: 'Timezone Offset From UTC', newValue: 'REMOVED', group: 'Other' },
  { tag: '00080300', description: 'Private Data Element Characteristics Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '00082111', description: 'Derivation Description', newValue: 'REMOVED', group: 'Other' },
  { tag: '00084000', description: 'Identifying Comments', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120010', description: 'Clinical Trial Sponsor Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120020', description: 'Clinical Trial Protocol ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120021', description: 'Clinical Trial Protocol Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120030', description: 'Clinical Trial Site ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120031', description: 'Clinical Trial Site Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120040', description: 'Clinical Trial Subject ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120042', description: 'Clinical Trial Subject Reading ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120050', description: 'Clinical Trial Time Point ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120051', description: 'Clinical Trial Time Point Description', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120060', description: 'Clinical Trial Coordinating Center Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120081', description: 'Clinical Trial Protocol Ethics Committee Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00120082', description: 'Clinical Trial Protocol Ethics Committee Approval Number', newValue: 'REMOVED', group: 'Other' },
  { tag: '00380300', description: 'Current Patient Location', newValue: 'REMOVED', group: 'Other' },
  { tag: '00380400', description: 'Patient\'s Institution Residence', newValue: 'REMOVED', group: 'Other' },
  { tag: '00380500', description: 'Patient State', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400001', description: 'Scheduled Station AE Title', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400002', description: 'Scheduled Procedure Step Start Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00400003', description: 'Scheduled Procedure Step Start Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00400004', description: 'Scheduled Procedure Step End Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00400005', description: 'Scheduled Procedure Step End Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '00400006', description: 'Scheduled Performing Physician\'s Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400007', description: 'Scheduled Procedure Step Description', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400009', description: 'Scheduled Procedure Step ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400010', description: 'Scheduled Station Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400011', description: 'Scheduled Procedure Step Location', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400012', description: 'Pre-Medication', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400275', description: 'Request Attributes Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400280', description: 'Comments on the Performed Procedure Step', newValue: 'REMOVED', group: 'Other' },
  { tag: '00400555', description: 'Acquisition Context Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '0040A124', description: 'UID', newValue: 'ANONYMIZED UID', group: 'Other' },
  { tag: '0040A730', description: 'Content Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '04000561', description: 'Original Attributes Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '20000010', description: 'Number of Copies', newValue: 'REMOVED', group: 'Other' },
  { tag: '20000020', description: 'Print Priority', newValue: 'REMOVED', group: 'Other' },
  { tag: '20000030', description: 'Medium Type', newValue: 'REMOVED', group: 'Other' },
  { tag: '20000040', description: 'Film Destination', newValue: 'REMOVED', group: 'Other' },
  { tag: '20000050', description: 'Film Session Label', newValue: 'REMOVED', group: 'Other' },
  { tag: '20000060', description: 'Memory Allocation', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100010', description: 'Image Display Format', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100030', description: 'Annotation Display Format ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100040', description: 'Film Orientation', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100050', description: 'Film Size ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100052', description: 'Printer Resolution ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100054', description: 'Default Printer Resolution ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100060', description: 'Magnification Type', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100080', description: 'Smoothing Type', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100100', description: 'Border Density', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100110', description: 'Empty Image Density', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100120', description: 'Min Density', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100130', description: 'Max Density', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100140', description: 'Trim', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100150', description: 'Configuration Information', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100160', description: 'Configuration Information Description', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100376', description: 'Printer Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100500', description: 'Referenced Film Box Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '20100510', description: 'Referenced Stored Print Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '21100010', description: 'Printer Status', newValue: 'REMOVED', group: 'Other' },
  { tag: '21100020', description: 'Printer Status Info', newValue: 'REMOVED', group: 'Other' },
  { tag: '21100030', description: 'Printer Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '21100099', description: 'Print Queue ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '40000010', description: 'Arbitrary', newValue: 'REMOVED', group: 'Other' },
  { tag: '40004000', description: 'Text Comments', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080040', description: 'Results ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080042', description: 'Results ID Issuer', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080050', description: 'Referenced Interpretation Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '400800FF', description: 'Report Production Status (Trial)', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080100', description: 'Interpretation Recorded Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '40080101', description: 'Interpretation Recorded Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '40080102', description: 'Interpretation Recorder', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080103', description: 'Reference to Recorded Sound', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080108', description: 'Interpretation Transcription Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '40080109', description: 'Interpretation Transcription Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '4008010A', description: 'Interpretation Transcriber', newValue: 'REMOVED', group: 'Other' },
  { tag: '4008010B', description: 'Interpretation Text', newValue: 'REMOVED', group: 'Other' },
  { tag: '4008010C', description: 'Interpretation Author', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080111', description: 'Interpretation Approver Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080112', description: 'Interpretation Approval Date', newValue: 'MODIFIED', group: 'Other' },
  { tag: '40080113', description: 'Interpretation Approval Time', newValue: 'MODIFIED', group: 'Other' },
  { tag: '40080114', description: 'Physician Approving Interpretation', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080115', description: 'Interpretation Diagnosis Description', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080117', description: 'Interpretation Diagnosis Code Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080118', description: 'Results Distribution List Sequence', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080119', description: 'Distribution Name', newValue: 'REMOVED', group: 'Other' },
  { tag: '4008011A', description: 'Distribution Address', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080200', description: 'Interpretation ID', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080202', description: 'Interpretation ID Issuer', newValue: 'REMOVED', group: 'Other' },
  { tag: '40080300', description: 'Impressions', newValue: 'REMOVED', group: 'Other' },
  { tag: '40084000', description: 'Results Comments', newValue: 'REMOVED', group: 'Other' },

  // Private Tags
  { tag: 'PRIVATE_TAGS', description: 'Remove Private Tags', newValue: 'REMOVED', group: 'Private Tags' },
];

export const ANONYMIZATION_GROUPS = [
  'Patient Information',
  'Study and Series Information',
  'Institution and Personnel',
  'Equipment and Procedure',
  'UIDs and References',
  'Other',
  'Private Tags'
];
