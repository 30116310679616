import * as cornerstone from 'cornerstone-core';
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';


// Initialize cornerstone and its dependencies
cornerstone.enable(document.createElement('div'));
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.webWorkerManager.initialize({
  taskConfiguration: {
    'decodeTask': {
      codecsPath: '/codecs/cornerstoneWADOImageLoaderCodecs.js'
    }
  }
});

/**
 * Converts a DICOM file to an image format.
 * @param {File} file - The DICOM file to convert.
 * @param {string} format - The desired output format (e.g., 'jpeg', 'png').
 * @returns {Promise<string>} A promise that resolves with the image data URL.
 */
export async function convertDicomToImage(file, format = 'jpeg') {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      try {
        const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
        const image = await cornerstone.loadImage(imageId);
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        cornerstone.renderToCanvas(canvas, image);

        const dataUrl = canvas.toDataURL(`image/${format}`);
        resolve(dataUrl);
      } catch (error) {
        reject(new Error('Failed to convert DICOM to image: ' + error.message));
      }
    };
    fileReader.onerror = () => reject(new Error('Failed to read file'));
    fileReader.readAsArrayBuffer(file);
  });
}
