// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBx-T-S7N6kG9TCY8xMpsw1_7rTrpz-2yo",
  authDomain: "dicom-react-535d6.firebaseapp.com",
  projectId: "dicom-react-535d6",
  storageBucket: "dicom-react-535d6.appspot.com",
  messagingSenderId: "544168854940",
  appId: "1:544168854940:web:73f7a55dacf1d7d8dff459"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);

// Firebase error handling function
export const getFirebaseErrorMessage = (errorCode) => {
  switch (errorCode) {
    // Authentication errors
    case 'auth/email-already-in-use':
      return 'This email is already associated with an account. Please use a different email or try logging in.';
    case 'auth/invalid-email':
      return 'The email address is not valid. Please check and try again.';
    case 'auth/weak-password':
      return 'The password is too weak. Please choose a stronger password with at least 6 characters.';
    case 'auth/user-not-found':
      return 'No account found with this email address. Please check your email or sign up for a new account.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again or use the "Forgot Password" option.';
    case 'auth/too-many-requests':
      return 'Too many unsuccessful attempts. Please try again later or reset your password.';
    case 'auth/user-disabled':
      return 'This account has been disabled. Please contact support for assistance.';
    case 'auth/operation-not-allowed':
      return 'This operation is not allowed. Please contact support if you believe this is an error.';
    case 'auth/invalid-credential':
      return 'The provided credential is invalid. Please try again with valid credentials.';
    case 'auth/invalid-verification-code':
      return 'The verification code is invalid. Please try again with a valid code.';
    case 'auth/invalid-verification-id':
      return 'The verification ID is invalid. Please request a new verification code.';
    case 'auth/expired-action-code':
      return 'The action code has expired. Please request a new one.';
    case 'auth/invalid-action-code':
      return 'The action code is invalid. Please check and try again or request a new one.';
    case 'auth/quota-exceeded':
      return 'Quota exceeded. Please try again later.';
    case 'auth/rejected-credential':
      return 'The credential was rejected. Please check your information and try again.';
    case 'auth/invalid-argument':
      return 'An invalid argument was provided to the authentication method.';
    case 'auth/invalid-password':
      return 'The password is invalid. Please check and try again.';
    case 'auth/invalid-persistence-type':
      return 'The specified persistence type is invalid.';
    case 'auth/missing-android-pkg-name':
      return 'An Android package name must be provided to install the app.';
    case 'auth/auth-domain-config-required':
      return 'The authDomain configuration is required for this operation.';
    case 'auth/missing-app-credential':
      return 'The app credential is missing. Please check your configuration.';
    case 'auth/missing-verification-code':
      return 'The verification code is missing. Please provide a valid code.';
    case 'auth/missing-verification-id':
      return 'The verification ID is missing. Please request a new verification code.';
    case 'auth/missing-iframe-start':
      return 'The internal iframe operation did not start. Please try again.';
    case 'auth/missing-or-invalid-nonce':
      return 'The request does not contain a valid nonce. Please try again.';
    case 'auth/missing-multi-factor-info':
      return 'Multi-factor authentication information is missing.';
    case 'auth/missing-multi-factor-session':
      return 'The multi-factor session is missing.';
    case 'auth/missing-phone-number':
      return 'The phone number is missing. Please provide a valid phone number.';
    case 'auth/missing-verification-completion-handler':
      return 'The verification completion handler is missing.';
    case 'auth/app-deleted':
      return 'This instance of FirebaseApp has been deleted.';
    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email address but different sign-in credentials.';
    case 'auth/network-request-failed':
      return 'A network error occurred. Please check your connection and try again.';
    case 'auth/null-user':
      return 'A null user object was provided as the argument.';
    case 'auth/popup-blocked':
      return 'The popup was blocked by the browser. Please allow popups for this site and try again.';
    case 'auth/popup-closed-by-user':
      return 'The popup was closed by the user before finalizing the operation.';
    case 'auth/unauthorized-domain':
      return 'The domain of the current URL is not authorized for OAuth operations.';
    case 'auth/user-token-expired':
      return 'The user\'s credential has expired. Please sign in again.';
    case 'auth/web-storage-unsupported':
      return 'Web storage is not supported or is disabled.';
    case 'auth/invalid-oauth-provider':
      return 'The OAuth provider is invalid. Please check the provider name.';
    case 'auth/operation-not-supported-in-this-environment':
      return 'This operation is not supported in the current environment.';
    case 'auth/timeout':
      return 'The operation has timed out. Please try again.';
    case 'auth/user-cancelled':
      return 'The user cancelled the authentication request.';

    // Default case
    default:
      return 'An error occurred. Please try again later.';
  }
};